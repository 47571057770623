import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export default function AuthenticateAuthRoute() {
  const authToken = localStorage.getItem('authToken');
  return authToken && authToken != 'undefined' ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Outlet />
  );
}
