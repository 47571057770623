import React, { Component, Fragment } from "react";
import Tabs from "react-responsive-tabs";


// Examples
import SalesDashboard1 from "./Examples/Variation1";
import PageTitleAlt from "../../../Layout/AppMain/PageTitleAlt";

const tabsContent = [
    {
        title: "SALES REPORT",
        content: <SalesDashboard1 />,
    },
    {
        title: "GOLFER ACTIVITY",
        content: <SalesDashboard1 />,
    },
    {
        title: "NEW ACCOUNTS",
        content: <SalesDashboard1 />,
    },
];
function getTabs() {
    return tabsContent.map((tab, index) => ({
        title: tab.title,
        getContent: () => tab.content,
        key: index,
    }));
}
export default function Dashboard() {
    return (
        <Fragment>
            <div className="app-inner-layout">
                <PageTitleAlt
                    heading="Dashboard"
                    subheading="This is the dashboard."
                />
                <Tabs tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big" transform={false}
                    showInkBar={true} items={getTabs()} />
            </div>
        </Fragment>
    );
}
