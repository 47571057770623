import React, { useState } from 'react'
import Complete from "../../assets/icons/complete.svg"

export default function ContactDetails({ userData }) {
    const [currentTab, setCurrentTab] = useState("email")
    const tabs = [
        { label: "EMAIL", value: "email" },
        { label: "PHONE", value: "phone" },
        { label: "ADDRESS", value: "address" }
    ]
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                CONTACT DETAILS
            </div>
            <div className='p-3 mt-3' style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                <div className='d-flex flex-wrap justify-content-between align-items-center'>
                    {tabs.map((tab, index) => (
                        <div key={index} className={`text-center py-2 ${tab?.value === currentTab ? "gradiant-green" : ""}`} style={{ boxShadow: tab?.value != currentTab ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", borderRadius: "10px", minWidth: "30%", cursor: "pointer" }} onClick={() => setCurrentTab(tab?.value)}>
                            <div style={{ fontWeight: "bold", color: tab?.value == currentTab ? "white" : "black" }}>{tab?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
            {
                currentTab === tabs[0].value &&
                <div className='mb-3 w-100'>
                    {userData?.userEmails?.length > 0 ? <div>
                        {
                            userData?.userEmails?.map((email) => (
                                <div className='d-flex align-items-center px-3 py-2 mt-2' style={{ border: "solid black 1px", borderRadius: "10px", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ fontWeight: "600", fontSize: "17px" }}>
                                            {email.email}
                                        </div>
                                        <div className={`${email.isVerified ? "text-primary" : "text-warning"}`}>
                                            {email.isVerified ? "Verified" : "Pending Verification"}
                                        </div>
                                    </div>
                                    {email.isVerified && <div className='d-flex align-items-center gap-2'>
                                        <img src={Complete} height={25} />
                                    </div>}
                                </div>
                            ))
                        }
                    </div> :
                        <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                            No Data Found
                        </div>
                    }
                </div>
            }
            {
                currentTab === tabs[1].value &&
                <div className='mb-3 w-100'>
                    {userData?.userPhones?.length > 0 ? <div>
                        {
                            userData?.userPhones?.map((phone) => (
                                <div className='d-flex align-items-center px-3 py-2 mt-2' style={{ border: "solid black 1px", borderRadius: "10px", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ fontWeight: "600", fontSize: "17px" }}>
                                            {phone.phoneCode} {phone.phoneNumber}
                                        </div>
                                        <div className={`${phone.isVerified ? "text-primary" : "text-warning"}`}>
                                            {phone.isVerified ? "Verified" : "Pending Verification"}
                                        </div>
                                    </div>
                                    {phone.isPrimary && <div className='d-flex align-items-center gap-2'>
                                        <img src={Complete} height={25} />
                                    </div>}
                                </div>
                            ))
                        }
                    </div> :
                        <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                            No Data Found
                        </div>
                    }
                </div>
            }
            {
                currentTab === tabs[2].value &&
                <div className='w-100'>
                    {userData?.userAddress?.length > 0 ? <div>
                        {
                            userData?.userAddress?.map((address) => (
                                <div className='px-3 pt-2 pb-3 mt-2' style={{ border: "solid black 1px", borderRadius: "10px" }}>
                                    <div className='d-flex align-items-center' style={{ justifyContent: "space-between" }}>
                                        <div>
                                            <div style={{ fontSize: "16px", fontWeight: "bold" }}>{address?.firstName?.toUpperCase()} {address?.middleName?.toUpperCase()} {address?.lastName?.toUpperCase()}</div>
                                        </div>
                                        {address.isPrimary && <div className='d-flex align-items-center gap-2'>
                                            <img src={Complete} height={25} />
                                        </div>}
                                    </div>
                                    <hr
                                        className='mt-3'
                                        style={{
                                            color: "black",
                                            height: 1,
                                            width: "inherit"
                                        }}
                                    />
                                    <div>
                                        <div>{address.areaBuildingName},</div>
                                        <div>{address.street},</div>
                                        <div>{address.cityName}-{address.postalCode},</div>
                                        <div>{address.stateName},</div>
                                        <div>{address.countryName}.</div>
                                        <div className='d-flex mt-2'>
                                            <div>Accommodation:</div>
                                            <div className='ms-2' style={{ fontWeight: "bold" }}>{address.accommodationType}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div>No of Bedrooms:</div>
                                            <div className='ms-2' style={{ fontWeight: "bold" }}>{address.noOfBedroom}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div> :
                        <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                            No Data Found
                        </div>
                    }
                </div>
            }
        </div>
    )
}
