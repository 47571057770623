import React from "react";
import { connect } from "react-redux";
import cx from "classnames";

import { toast, Slide } from "react-toastify";


class PageTitle extends React.Component {
  state = {
    expZoomIn: false,
  };

  toggle(name) {
    this.setState({
      [name]: !this.state[name],
      progress: 0.5,
    });
  }

  notify22 = () =>
  (this.toastId = toast("You can add whatever element in this section.", {
    transition: Slide,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-center",
    type: "default",
  }));

  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      button,
      heading,
      icon,
      subheading,
      paddingValue,
      margin,
      marginBottom,
      marginTop
    } = this.props;
    return (
      <div className="app-page-title bg-white" style={{ padding: paddingValue ? paddingValue : "1rem 1.5rem", margin: margin, marginTop: marginTop, marginBottom: marginBottom }}>
        <div className="page-title-wrapper d-flex justify-content-between">
          <div className="page-title-heading">
            <div className={cx("page-title-icon bg-page-title-icon ", {
              "d-none": !enablePageTitleIcon,
            })} style={{ height: "40px", width: "40px", padding: "0.5rem" }}>
              {icon}
            </div>
            <div>
              <h4 className="mb-0">{heading}</h4>
              <div className={cx("page-title-subheading", {
                "d-none": !enablePageTitleSubheading,
              })}>
                {subheading}
              </div>
            </div>
          </div>
          {button && button}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
