import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import { Col } from 'reactstrap'

export default function AuthLayout() {
    return (
        <Fragment>
            <div className="app-container">
                <div className='h-100 user-page-bg  bg-animation'>
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <Col md="8" className="mx-auto app-login-box">
                            {/* User Pages */}
                            <Outlet />
                            <div className="text-center text-white opacity-8 mt-3">
                                Copyright &copy; 4Hire 2023
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}
