import React, { useContext } from 'react'
import ReactTables from '../../components/ReactTable'
import PageTitleAlt from '../../Layout/AppMain/PageTitleAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { ReactComponent as Delete } from "../../assets/icons/delete.svg"
import { ReactComponent as Edit } from "../../assets/icons/edit.svg"
import { ReactComponent as Suspend } from "../../assets/icons/suspend.svg"
import { ReactComponent as View } from "../../assets/icons/view.svg"
import Avtar from "../../assets/images/avatar.png"
import UsersIcon from "../../assets/icons/users.svg"
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg"
import sweetalertConfig from '../../config/sweetalert-config';
import { useState } from 'react';
import { useEffect } from 'react';
import { errorMessage } from '../../helpers/catchErrorMessage';
import { activateUser, deleteUser, getUserList, suspendUser } from '../../service/userService';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import "./css/userProfile.css"
import moment from 'moment';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { loaderContext } from '../../config/context';

export default function Users() {
    const [usersData, setUsersData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const navigate = useNavigate()
    const [showColumns, setShowColumns] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const loadUserData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getUserList();
            const newData = data?.data.map((user) => {
                user.phoneNumber = `${user?.phoneCode}-${user?.phoneNumber}`
                return user
            })
            setUsersData(newData);
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        loadUserData()
        setShowColumns(columns)
    }, [])
    const firstNameSort = (rowA, rowB) => {
        const a = rowA.firstName.toLowerCase();
        const b = rowB.firstName.toLowerCase();

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };
    const dateSort = (rowA, rowB) => {
        const a = moment(rowA.createdAt).toDate();
        const b = moment(rowB.createdAt).toDate();

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };

    let columns = [
        {
            id: 0,
            name: "#",
            value: true,
            width: "4rem",
            selector: row => {
                return (
                    <div style={{ width: "fit-content" }}>
                        <img src={row?.profilePhoto ? row?.profilePhoto : Avtar} height="30px" width style={{ borderRadius: "100%", aspectRatio: "1" }} />
                    </div>
                );
            }
        },
        {
            id: 1,
            name: "FIRST NAME",
            width: "8rem",
            sortable: true,
            sortFunction: firstNameSort,
            value: true,
            selector: row => {
                return (
                    <div className='d-flex align-items-center'>
                        {/* <img src={row?.profilePhoto ? row?.profilePhoto : Avtar} height="35px" style={{ borderRadius: "100%", aspectRatio: "1" }} /> */}
                        <div className='ms-2'>
                            {row.firstName}
                        </div>
                    </div>
                );
            }
        },
        {
            id: 2,
            name: "MIDDLE NAME",
            width: "10rem",
            sortable: true,
            value: true,
            selector: row => row.middleName,
        },
        {
            id: 3,
            name: "LAST NAME",
            width: "8rem",
            sortable: true,
            value: true,
            selector: row => row.lastName,
        },
        {
            id: 4,
            name: "USER TYPE",
            sortable: true,
            value: true,
            selector: row => row.userType,
            width: "8rem",
        },
        {
            id: 5,
            name: "EMAIL",
            width: "13rem",
            sortable: true,
            value: true,
            selector: row => row.email
        },
        {
            id: 6,
            name: "PHONE NUMBER",
            sortable: true,
            value: true,
            selector: row => row.phoneNumber,
            width: "13rem",
        },
        {
            id: 7,
            name: "ACCOUNT TYPE",
            sortable: true,
            value: true,
            selector: row => row.accountType,
            width: "9rem",
        },
        {
            id: 8,
            name: "COUNTRY",
            sortable: true,
            value: true,
            selector: row => row.country,
            width: "8rem"
        },
        {
            id: 9,
            name: "STATUS",
            sortable: true,
            value: true,
            selector: row => row.status
        },
        {
            id: 10,
            name: "MEMBER SINCE",
            sortable: true,
            sortFunction: dateSort,
            value: true,
            valueType: "dateAndTime",
            width: "9rem",
            selector: row => {
                return (
                    <div>
                        <div>
                            {`${moment(row.createdAt).format("DD")} ${moment(row.createdAt).format("MMM").toUpperCase()} ${moment(row.createdAt).format("YYYY")}`}
                        </div>
                        <div>
                            {`${moment(row.createdAt).format("hh:mm")} ${moment(row.createdAt).format("a")[0]}.${moment(row.createdAt).format("a")[1]}.`}
                        </div>
                    </div>
                )
            }
        },
        {
            id: 11,
            name: "ACTION",
            value: true,
            width: "13rem",
            selector: data => (
                <div className='d-flex'>
                    <span
                        className="btn me-2 action-button"
                        title="View"
                        onClick={() => onViewUser(data._id)}
                    >
                        {/* <img src={View} height={18} width={18} style={{ fill: "#ffffff", stroke: "#ddffdd" }} /> */}
                        <View height={18} width={18} className='svg-icon' />
                    </span>
                    <span
                        className="btn me-2 action-button"
                        title="Edit"
                        onClick={() => onEditUser(data._id)}
                    >
                        {/* <img src={Edit} height={15} width={15} style={{ fill: "#000000" }} /> */}
                        <Edit height={15} width={15} className='svg-icon' />

                    </span>
                    <span
                        className="btn me-2 action-button"
                        title="Suspend"
                        onClick={() => onSuspendUser(data)}
                    >
                        {/* <img src={Suspend} height={15} width={15} /> */}
                        <Suspend height={15} width={15} className='svg-icon' />

                    </span>
                    <span
                        className="btn action-button"
                        title="Delete"
                        onClick={() => onDeleteUser(data._id)}
                        style={{ fontWeight: "bold" }}
                    >
                        {/* <img src={Delete} height={15} width={15} style={{ fontWeight: "bold" }} /> */}
                        <Delete height={15} width={15} className='svg-icon' />

                    </span>
                </div>
            ),
        }
    ];
    const onEditUser = (data) => {

    }
    const onDeleteUser = (userData) => {
        console.log("uerId", userData)
        Swal.fire({
            title: "Delete",
            text: "Are you sure you want to delete this user?",
            icon: 'warning',
            ...sweetalertConfig.confirmation,
            confirmButtonText: "Delete"
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const { data } = await deleteUser(userData);
                    // setUsersData(usersData.filter((user) => user._id != userData))
                    loadUserData()
                    Swal.fire({
                        icon: 'success',
                        title: "Deleted",
                        text: data.message,
                        ...sweetalertConfig.simple
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    }
    const onViewUser = (data) => {
        navigate(`/user-profile`, { state: { userId: data, backUrl: "/users" } })
    }
    const onSuspendUser = (userData) => {
        if (userData?.status === "suspend") {
            Swal.fire({
                title: "Activate",
                text: "Are you sure you want to activate this user?",
                icon: 'warning',
                ...sweetalertConfig.confirmation,
                confirmButtonText: "Activate"
            }).then(async result => {
                if (result.isConfirmed) {
                    setIsLoading(true)
                    try {
                        const { data } = await activateUser(userData._id);
                        Swal.fire({
                            icon: 'success',
                            title: "Activated",
                            text: data.message,
                            ...sweetalertConfig.simple
                        });
                        loadUserData();
                    } catch (e) {
                        errorMessage(e)
                    }
                    setIsLoading(false)
                }
            });
        } else {
            Swal.fire({
                title: "Suspend",
                text: "Are you sure you want to suspend this user?",
                icon: 'warning',
                ...sweetalertConfig.confirmation,
                confirmButtonText: "Suspend"
            }).then(async result => {
                if (result.isConfirmed) {
                    setIsLoading(true)
                    try {
                        const { data } = await suspendUser(userData._id);
                        Swal.fire({
                            icon: 'success',
                            title: "Suspended",
                            text: data.message,
                            ...sweetalertConfig.simple
                        });
                        loadUserData();
                    } catch (e) {
                        errorMessage(e)
                    }
                    setIsLoading(false)
                }
            });
        }
    }
    const setColumnValue = (column) => {
        const columns = showColumns.map((columnName) => {
            if (columnName.id === column) {
                columnName.value = !columnName.value
            }
            return columnName;
        })
        setShowColumns(columns)
    }
    return (
        <>
            <PageTitleAlt heading="GOLFERS"
                // subheading="This is the list of all users."
                icon={<img src={UsersIcon} style={{ height: "100%", width: "100%", fill: "white" }} />} />
            <div className="row mb-3 g-3">
                {/* <div className="card overflow-hidden mb-3"> */}
                {/* <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    Users
                                </h3>
                            </div>
                        </div>
                    </div> */}

                {/* <div className="card-body pt-3" style={{ minHeight: "25rem" }}> */}
                <ReactTables
                    columns={showColumns.filter((col) => col.value)}
                    // columns={columns}
                    data={usersData}
                    // pageSizeOption={[100, 150, 200, 250]}
                    tableHeading="GOLFERS"
                    searchColumns={[{ key: "firstName" }, { key: "middleName" }, { key: "lastName" }, { key: "userType" }, { key: "email" }, { key: "phoneNumber" }, { key: "accountType" }, { key: "status" }, { key: "country" }, { key: "createdAt", type: "date", format: "DD MMM YYYY hh:mm a" }]}
                    filterComponent={
                        <div style={{ display: "block", maxWidth: "fit-content" }}>
                            {/* <Button className={`d-flex align-items-center p-0 justify-content-center table-filter-btn ${showFilter ? "bg-primary" : ""}`} style={{ width: "2.5rem", height: "2rem" }} onClick={() => setShowFilter(!showFilter)}>
                                <img src={FilterIcon} height={20} width={20} style={{ fill: "#ffffff" }} />
                            </Button> */}
                            <Button className="d-flex justify-content-center btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => setShowFilter(!showFilter)}>
                                {/* <img src={FilterIcon} height={20} width={20} style={{ fill: "#00000" }} /> */}
                                <FilterIcon height={20} width={20} className="filter-btn" />
                            </Button>
                            {showFilter && <div className='position-absolute card py-2 px-3' style={{ marginTop: "1.3%", marginLeft: "-10%", width: "10rem", zIndex: "9999" }}>
                                {showColumns.map((column, index) => (
                                    <div className="py-1" key={index}>
                                        <FormGroup check>
                                            <Input type="checkbox" checked={column.value} onClick={() => setColumnValue(column.id)} />
                                            <Label check>
                                                {column.name}
                                            </Label>
                                        </FormGroup>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    }
                />
                {/* </div> */}
                {/* </div> */}
            </div>
        </>
    )
}
