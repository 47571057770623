export const loginForm = {
    "phoneNoReq": "Please enter your phone number.",
    "phoneNoInvalid": "Please enter valid phone number."
}
export const verifyOTPForm = {
    "otpReq": "Please enter otp.",
    "otpInvalid": "Please enter valid otp."
}

export const addAdmin = {
    "firstNameReq": "Please enter your First Name.",
    "firstNameInvalid": "Please enter valid First Name.",
    "lastNameReq": "Please enter your Last Name.",
    "lastNameInvalid": "Please enter valid Last Name.",
    "emailReq": "Please enter your Email.",
    "emailInvalid": "Please enter valid Email.",
    "phoneNoReq": "Please enter your phone number.",
    "phoneNoInvalid": "Please enter valid phone number."
}
