import React, { Fragment } from 'react'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'
import AppFooter from './AppFooter'
import { Outlet } from 'react-router-dom'

export default function ChatLayout() {
    return (
        // <Fragment>
        //     {/* <ThemeOptions /> */}
        //     <AppHeader headerBackgroundColor="headerBackgroundColor" />
        //     <div className="app-main">
        //         <AppSidebar />
        //         <div className="app-main__outer pb-0">
        //             <div className="app-main__inner p-0">
        //                 {/* Dashboard Widgets */}
        //                 <Outlet />
        //             </div>
        //         </div>
        //     </div>
        // </Fragment>
        <Fragment>
            {/* <ThemeOptions /> */}
            <AppHeader headerBackgroundColor="headerBackgroundColor" />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner px-0">
                        {/* Dashboard Widgets */}
                        <Outlet />
                    </div>
                    {/* <AppFooter /> */}
                </div>
            </div>
        </Fragment>
    )
}
