import moment from 'moment'
import React from 'react'

export default function CreditCardDetails({ userData }) {
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                CREDIT / DEBIT CARDS
            </div>
            {userData?.userCards.length > 0 ? <div>
                {
                    userData.userCards.map((card) => (
                        <div className='d-flex align-items-center px-3 py-2 mt-2' style={{ border: "solid black 1px", borderRadius: "10px", justifyContent: "space-between" }}>
                            <div>
                                <div style={{ fontWeight: "600", fontSize: "17px" }}>
                                    {`XXXX-XXXX-XXXX-${card.cardNumber.substr(card.cardNumber.length - 4)}`}
                                </div>
                                <div>
                                    {`Valid up to ${moment(card.validUntilDate).format("MMM YYYY")}`}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div> :
                <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                    No Data Found
                </div>
            }

        </div>
    )
}
