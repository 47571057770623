import React, { useEffect, useState } from 'react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import '../assets/css/custom-dropdown.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'reactstrap';

export default function PhoneNumber({
  value,
  onChange,
  countryCode,
  setCountryCode,
  placeholder,
  error,
  allCountryCodes,
  dropdownDirection,
  maxLength
}) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [countryCodes, setCountryCodes] = useState();
  console.log(countryCodes)
  useEffect(() => {
    setCountryCodes(allCountryCodes);
  }, [allCountryCodes]);
  useEffect(() => {
    setCountryCodes(
      _.filter(allCountryCodes, country => {
        if (
          country?.countryCode?.toString().includes(searchQuery.toLowerCase()) ||
          country?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
      })
    );
  }, [searchQuery]);
  const changeCountry = data => {
    setCountryCode(data);
    setSearchQuery('');
    setDropdownStatus(false);
  };
  const handleOutsideClick = () => {
    setSearchQuery('');
    setDropdownStatus(false);
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        handleOutsideClick();
      }}
    >
      <div className="input-group">
        <div className="input-group-prepend " role="button">
          <div
            className="d-flex align-items-center form-control py-2 px-2 country-dropdown"
            onClick={() => setDropdownStatus(!dropdownStatus)}
            style={{ height: "100%" }}
          >
            <div
              className="d-flex align-items-center"
              title={`${countryCode?.name} : ${countryCode?.countryCode}`}
            >
              <img className="me-1 flag-image" src={countryCode?.flag} />
              <span className="me-1">{countryCode?.countryCode}</span>
              {dropdownStatus ? <FontAwesomeIcon
                icon={faCaretUp}
                className="ms-1"
              /> : <FontAwesomeIcon
                icon={faCaretDown}
                className="ms-1"
              />}
            </div>
          </div>

          {dropdownStatus && (
            <div
              className={`dropdown-options position-absolute p-2 ${dropdownDirection === 'top'
                ? 'bottom-100'
                : dropdownDirection === 'bottom' && ''
                }`}
              style={{ zIndex: 99999 }}
            >
              <input
                className="form-control search-bar"
                type="text"
                placeholder="Search country"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
              {countryCodes?.length > 0 ? (
                countryCodes?.map((country, index) => (
                  <div
                    key={index}
                    onClick={() => changeCountry(country)}
                    className="country-name"
                    role="button"
                  >
                    <img className="me-1 flag-image" src={country?.flag} />
                    <span className="me-1">{country?.name}</span>
                    <span className="me-4">{country?.countryCode}</span>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <h5 className="text-danger">Data not found.</h5>
                </div>
              )}
            </div>
          )}
        </div>
        <Input
          className={`form-control zindex-0 ${error ? 'is-invalid' : ''}`}
          type="text"
          value={!value ? '' : value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
          size="lg"
        />
      </div>
    </OutsideClickHandler>
  );
}
PhoneNumber.defaultProps = {
  placehoder: '',
  dropdownDirection: 'top'
};
