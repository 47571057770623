import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

export default function ConfirmationDetails({ userData }) {
    const confirmationData = [
        { label: "Photos", value: "photos" },
        { label: "ID Card / Passport", value: "idCardsPassport" },
        { label: "Driver's License", value: "driverLicense" },
        { label: "Bank Account", value: "bankAccount" },
        { label: "Medical Certification", value: "medicalCertificate" },
        { label: "I agree to the terms and conditions of Greensboro National Golf Club correct under penalty of law.(Powerd by: 4Hire, LLC)  and certify the information provided is true and correct under penalty of law.", value: "agreementTerms1" },
        // { label: "I agree that every time I accept a Greensboro National Golf Course Club service that I am healthy with a body temperature below 37.5 Celsius and not contagious with any serious or life threatening diseases. And will stop working and see a doctor of I display any symptoms.", value: "agreementTerms2" },
    ]
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                CONFIRMATION DETAILS
            </div>
            <div className='px-2'>
                {confirmationData.map((conf) => (
                    <div className='mt-3'>
                        <FormGroup check >
                            <Input type="checkbox" style={{ marginTop: "6px" }} checked={userData?.confirmation[conf.value]} />
                            <Label check>
                                <div style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>{conf.label}</div>
                            </Label>
                        </FormGroup>
                    </div>
                ))}
            </div>
        </div>
    )
}
