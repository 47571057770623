import React, { Fragment } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import FullLogo from "../../../assets/utils/images/full-logo.png"
import "../Userpages.css"
import { useNavigate } from "react-router-dom";
// Layout

const ForgotPasswordBoxed = () => {
  const navigate = useNavigate()
  const sendOTP = () => {
    navigate("/verify-otp")
  }
  return (
    <Fragment>

      <div className="app-logo-inverse mx-auto mb-3" />
      <div className="modal-dialog w-100 mx-auto">
        <div className="modal-content">
          <div className="d-flex align-items-center justify-content-center w-100">
            <img src={FullLogo} height="220rem" width="400rem" />
          </div>
          <div className="modal-body pt-2">
            <h2 className="user-page-heading">Forgot Password</h2>
            <div className="h5 modal-title text-center">
              <h4 className="mt-2">
                <span>Use the form below to recover it.</span>
              </h4>
            </div>
            <Form>
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Input type="email" name="email" id="exampleEmail" size="lg" placeholder="Enter Your Email Address" />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <div className="d-flex justify-content-end">
              <div className="btn text-primary">
                <a onClick={() => navigate("/login")}>
                  Back To Sign In
                </a>
              </div>
            </div>
          </div>
          <div className="modal-footer clearfix">
            <div className="w-100">
              <Button color="primary" className="d-flex w-100 justify-content-center" style={{ color: "#ffffff" }} onClick={() => sendOTP()} size="lg">
                <div className="fs-5">Send OTP</div>
              </Button>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}


export default ForgotPasswordBoxed;
