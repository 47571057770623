// import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import SearchBox from './SearchBox';
// import "./css/pagination.css"
// import {
//   useTable,
//   useFilters,
//   useSortBy,
//   usePagination,
//   useGlobalFilter,
//   useExpanded,
//   useGroupBy
// } from 'react-table';
// import { faSort, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons"
// import moment from 'moment';
// const defaultHeaderStyle = {

// }

// export default function ReactTables({
//   minWidth,
//   columns,
//   data,
//   pageSizeOption,
//   isPageNumberIndecatorRequired,
//   isPageSizeDropdownRequired,
//   isPaginationRequired,
//   headerClass,
//   isSearchBoxVisible,
//   component,
//   bodyClass,
//   renderRowSubComponent,
//   headerStyle = defaultHeaderStyle
// }) {
//   const [filterInput, setFilterInput] = useState('');
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     prepareRow,
//     page,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     setGlobalFilter,
//     state: { pageIndex, pageSize }
//   } = useTable(
//     {
//       columns,
//       data,
//       initialState: { pageIndex: 0, pageSize: pageSizeOption[0] } // Pass our hoisted table state
//     },
//     useFilters,
//     useGlobalFilter,
//     useGroupBy,
//     useSortBy,
//     useExpanded,
//     usePagination
//   );
//   const handleFilterChange = e => {
//     const value = e.target.value || undefined;
//     setGlobalFilter(value || undefined);
//     setFilterInput(value === undefined ? '' : value);
//   };
//   return (
//     <div>
//       {isSearchBoxVisible && (
//         <div className="mb-3 row justify-content-end align-items-center">
//           {component}
//           <div className="col-md-4">
//             <SearchBox onChange={handleFilterChange} value={filterInput} />
//           </div>
//         </div>
//       )}
//       <div className="table-responsive scrollbar mb-4">
//         <table
//           {...getTableProps()}
//           className="table table-hover table-md table-striped overflow-hidden mb-0"
//           style={{ minWidth: minWidth }}
//         >
//           <thead className={headerClass} style={{ backgroundColor: "#7ab82c", border: "none", color: "white", height: "3rem" }}>
//             {headerGroups.map((headerGroup, i) => (
//               <React.Fragment key={i}>
//                 <tr {...headerGroup.getHeaderGroupProps()}>
//                   {headerGroup.headers.map(column => (
//                     <th
//                       {...column.getHeaderProps(column.getSortByToggleProps())}
//                       className={`${column.className && column.className}`}
//                     >
//                       {column.render('Header')}
//                       {/* Add a sort direction indicator */}
//                       {!column.disableSortBy && (
//                         <span className="ms-2">
//                           <FontAwesomeIcon
//                             icon={
//                               column.isSorted
//                                 ? column.isSortedDesc
//                                   ? faArrowDown
//                                   : faArrowUp
//                                 : faSort
//                             }
//                           />
//                         </span>
//                       )}
//                     </th>
//                   ))}
//                 </tr>
//               </React.Fragment>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()} className={bodyClass}>
//             {page.length > 0 ? (
//               page.map((row, i) => {
//                 prepareRow(row);
//                 return (
//                   <React.Fragment key={i}>
//                     <tr {...row.getRowProps()} style={{ height: "3rem", fontWeight: "500" }}>
//                       {row.cells.map((cell, index) => {
//                         return cell.column.content ? (
//                           <td
//                             {...cell.getCellProps()}
//                             key={index}
//                             className={`${cell.column.className && cell.column.className
//                               }`}
//                           >
//                             {cell.column.content(cell.row.original)}
//                           </td>
//                         ) : cell.column.isImage ? (
//                           <td
//                             {...cell.getCellProps()}
//                             key={index}
//                             className={`${cell.column.className && cell.column.className
//                               }`}
//                           >
//                             {cell.value ?
//                               <img src={cell.value} height="32px" width="32px" />
//                               :
//                               <img src='/admin-images/avatar.png' height="32px" width="32px" />
//                             }
//                           </td>
//                         ) : cell.column.valueType === 'date' ||
//                           cell.column.valueType === 'dateAndTime' ||
//                           cell.column.valueType === 'time' ? (
//                           <td
//                             key={index}
//                             className={`${cell.column.className && cell.column.className
//                               }`}
//                           >
//                             {cell.column.valueType === 'date'
//                               ? moment(
//                                 cell.render('Cell').props.cell.value
//                               ).format(
//                                 process.env.REACT_APP_DISPLAY_DATE_FORMAT
//                               )
//                               : cell.column.valueType === 'dateAndTime'
//                                 ? moment(
//                                   cell.render('Cell').props.cell.value
//                                 ).format(
//                                   process.env.REACT_APP_DISPLAY_DATE_TIME_FORMAT
//                                 )
//                                 : cell.render('Cell').props.cell.value != "Invalid Date" ? moment(
//                                   cell.render('Cell').props.cell.value
//                                 ).format(
//                                   process.env.REACT_APP_DISPLAY_TIME_FORMAT
//                                 ) : "--:--"}
//                           </td>
//                         ) : (
//                           <td
//                             {...cell.getCellProps()}
//                             key={index}
//                             className={`${cell.column.className && cell.column.className
//                               }`}
//                           >
//                             {cell.render('Cell')}
//                           </td>
//                         );
//                       })}
//                     </tr>
//                     {row.isExpanded ? (
//                       <>
//                         <tr>
//                           <td colSpan={columns.length}>
//                             {renderRowSubComponent && renderRowSubComponent({ row })}
//                           </td>
//                         </tr>
//                       </>
//                     ) : null}
//                   </React.Fragment>
//                 );
//               })
//             ) : (
//               <tr>
//                 <td colSpan={`${columns.length + 1}`}>
//                   <h5 className="text-center text-danger">
//                     Data not found.
//                   </h5>
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>

//       <div className="d-flex flex-wrap align-items-center justify-content-between">
//         <div className="d-flex flex-wrap align-items-center">
//           {isPageNumberIndecatorRequired && (
//             <div className="me-3 d-flex flex-wrap align-items-center">
//               <span>
//                 Page{' '}
//                 <strong>
//                   {pageIndex + 1} of {pageOptions.length}
//                 </strong>{' '}
//               </span>
//             </div>
//           )}
//           {isPageSizeDropdownRequired && (
//             <div>
//               <div className="d-flex flex-wrap align-items-center">
//                 <div className="me-2">Page size : </div>
//                 <div>
//                   <select
//                     className="form-select"
//                     value={pageSize}
//                     onChange={e => {
//                       setPageSize(Number(e.target.value));
//                     }}
//                   >
//                     {pageSizeOption.map(pageSize => (
//                       <option key={pageSize} value={pageSize}>
//                         {pageSize}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//         {!canPreviousPage && !canNextPage && isPaginationRequired ? null : (
//           <div className="d-flex flex-wrap">
//             <div
//               onClick={() => gotoPage(0)}
//               className={`px-3 py-2 border rounded pagination-btn ${!canPreviousPage && 'disabled'
//                 }`}
//               title="Go to first page"
//             >
//               <span>{'<<'}</span>
//             </div>{' '}
//             <div
//               onClick={() => previousPage()}
//               className={`px-3 py-2 border rounded pagination-btn ${!canPreviousPage && 'disabled'
//                 }`}
//               title="Go to previous page"
//             >
//               <span>{'<'}</span>
//             </div>{' '}
//             <div
//               onClick={() => nextPage()}
//               className={`px-3 py-2 border rounded pagination-btn ${!canNextPage && 'disabled'
//                 }`}
//               title="Go to next page"
//             >
//               <span>{'>'}</span>
//             </div>{' '}
//             <div
//               onClick={() => gotoPage(pageCount - 1)}
//               className={`px-3 py-2 border rounded pagination-btn ${!canNextPage && 'disabled'
//                 }`}
//               title="Go to last page"
//             >
//               <span>{'>>'}</span>
//             </div>
//           </div>
//         )}
//       </div>
//     </div >
//   );
// }
// ReactTables.defaultProps = {
//   pageSizeOption: [100, 150, 200, 250],
//   isPaginationRequired: true,
//   isPageNumberIndecatorRequired: true,
//   isPageSizeDropdownRequired: true,
//   isSearchBoxVisible: true
// };
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Row, Col, Card, CardBody, Button, InputGroup, Input } from "reactstrap";

import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./css/table.css"
import moment from "moment";
import { escapeLeadingUnderscores } from "typescript";

export default function ReactTable({ columns, data, filterComponent, searchColumns, tableHeading, SecondHeader, headerLeft }) {
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false)
    const [tableData, setTableData] = useState([])
    const [filterTableData, setFilterTableData] = useState([])
    const [quearyString, setQuearyString] = useState("")
    const customStyles = {
        headCells: {
            style: {
                background: "linear-gradient(300deg, #7ab82c 0%, #116f1a 100%)",
                color: "#ffffff"
            },
        }
    };
    useEffect(() => {
        setTableData(data)
        setFilterTableData(data)
    }, [data])
    useEffect(() => {
        if (quearyString != "") {
            setFilterTableData(tableData?.filter((row) => {
                const searchResult = searchColumns?.filter((searchCol) => {
                    if (searchCol.type == "date") {
                        return moment(row[searchCol?.key]).format(searchCol?.format)?.toLowerCase()?.includes(quearyString?.toLowerCase())
                    } else {
                        return row[searchCol?.key]?.toString()?.toLowerCase()?.includes(quearyString?.toLowerCase())
                    }
                })
                return searchResult.length <= 0 ? false : true
            }))
        } else {
            setFilterTableData(tableData)
        }
    }, [quearyString])

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-2">
                                    <CardBody style={{ padding: "0.5rem 1rem" }}>
                                        <div className="d-flex  align-items-center col-12">
                                            <div className="col-4" style={{ display: 'flex', justifyContent: "flex-start" }}>
                                                {headerLeft && headerLeft}
                                            </div>
                                            <div className="col-4" style={{ fontWeight: "bold", fontSize: 17, display: 'flex', justifyContent: "center" }}>
                                                {tableHeading}
                                            </div>
                                            <div className="col-4" style={{ display: 'flex', justifyContent: "flex-end" }}>
                                                {filterComponent && filterComponent}
                                                {isSearchBarVisible &&
                                                    <div className="ps-3 ms-3" style={{ backgroundColor: "white" }}>
                                                        <InputGroup>
                                                            <div className="input-group-text">
                                                                <FontAwesomeIcon icon={faSearch} />
                                                            </div>
                                                            <Input placeholder="Search..." value={quearyString} onChange={(e) => setQuearyString(e.target.value)} />
                                                        </InputGroup>
                                                    </div>}
                                                {/* <Button className={`d-flex align-items-center p-0 justify-content-center table-filter-btn ms-3 ${isSearchBarVisible ? "bg-primary" : ""}`} style={{ width: "2.5rem", height: "2rem" }} onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>

                                                </Button> */}
                                                <Button className="btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
                                                    <FontAwesomeIcon icon={faSearch} size={10} />
                                                </Button>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                {SecondHeader && <Card className="main-card mb-2">
                                    <CardBody style={{ padding: "0.5rem 1rem" }}>
                                        {SecondHeader}
                                    </CardBody>
                                </Card>}
                                <Card className="main-card">
                                    <CardBody>
                                        <DataTable data={filterTableData}
                                            columns={columns}
                                            pagination
                                            fixedHeader
                                            fixedHeaderScrollHeight="430px"
                                            customStyles={customStyles}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    );
}
