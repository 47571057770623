import React from 'react'

export default function EmergencyContact({ userData }) {
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                EMERGENCY CONTRACT
            </div>
            {userData?.userEmergencyContacts?.length > 0 ? <div>
                {
                    userData?.userEmergencyContacts?.map((emergancyContact) => (
                        <div className='px-3 pt-2 pb-3 mt-3' style={{ border: "solid black 1px", borderRadius: "10px" }}>
                            <div className='d-flex align-items-center' style={{ justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>{emergancyContact?.firstName?.toUpperCase()} {emergancyContact?.middleName?.toUpperCase()} {emergancyContact?.lastName?.toUpperCase()}</div>
                                </div>
                            </div>
                            <hr
                                className='mt-3'
                                style={{
                                    color: "black",
                                    height: 1,
                                    width: "inherit"
                                }}
                            />
                            <div>
                                <div className='col-12 d-flex'>
                                    <div className='col-3' style={{ fontWeight: "600", color: "black" }}>Relationship :</div>
                                    <div className='col-9' style={{ color: "black" }}>{emergancyContact?.relationType}</div>
                                </div>
                                <div className='col-12 d-flex'>
                                    <div className='col-3' style={{ fontWeight: "600", color: "black" }}>Phone :</div>
                                    <div className='col-9 flex-wrap d-flex' style={{ color: "black" }}>{emergancyContact?.phoneNumber}</div>
                                </div>
                                <div className='col-12 d-flex'>
                                    <div className='col-3' style={{ fontWeight: "600", color: "black" }}>Email :</div>
                                    <div className='col-9' style={{ color: "black" }}>{emergancyContact?.email}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div> :
                <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                    No Data Found
                </div>
            }
        </div>
    )
}
