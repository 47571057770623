import httpServices from "./httpServices";
import { masterAdminEndPoint } from "./config";

export const getAllMasterAdmins = async () => {
    return await httpServices.get(masterAdminEndPoint.listMasterAdmin)
}

export const getMasterAdminById = async (adminId) => {
    return await httpServices.get(`${masterAdminEndPoint.getMasterAdmin}/${adminId}`)
}

export const addMasterAdmin = async (adminData) => {
    return await httpServices.post(masterAdminEndPoint.addMasterAdmin, adminData)
}

export const updateMasterAdmin = async (adminData) => {
    const { _id, ...restData } = adminData;
    return await httpServices.put(`${masterAdminEndPoint.editMasterAdmin}/${_id}`, restData)
}

export const deleteMasterAdmin = async (adminId) => {
    return await httpServices.delete(`${masterAdminEndPoint.deleteMasterAdmin}/${adminId}`)
}

export const activateMasterAdmin = async (adminId) => {
    return await httpServices.put(`${masterAdminEndPoint.activateMasterAdmin}/${adminId}`)
}

export const suspendMasterAdmin = async (adminId) => {
    return await httpServices.put(`${masterAdminEndPoint.suspendMasterAdmin}/${adminId}`)
}