import { chatEndPoint } from "./config"
import httpServices from "./httpServices"

export const getChannelList = async (userId) => {
    return await httpServices.get(`${chatEndPoint.getChannelList}/${userId}`)
}

export const getChatConversasion = async (channelId) => {
    return await httpServices.get(`${chatEndPoint.getChatConversasion}/${channelId}`)
}

export const endChatConverstion = async (data) => {
    return await httpServices.post(`${chatEndPoint?.endChatConverstion}`, data)
}

export const readUnreadChat = async (data) => {
    return await httpServices.post(`${chatEndPoint?.readChat}`, data)
}