import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useState, useEffect } from "react";
// import Loader from "react-loaders";
import { ToastContainer } from "react-toastify";
import LoginBoxed from "../../DemoPages/UserPages/LoginBoxed";
import MainLayout from "../MainLayout";
import AuthLayout from "../AuthLayout";
import Dashboard from "../../DemoPages/Dashboards/Dashboard/Dashboard";
import ForgotPasswordBoxed from "../../DemoPages/UserPages/ForgotPasswordBoxed";
import AuthenticateRoute from "../../common/AuthenticateRoute";
import AuthenticateAuthRoute from "../../common/AuthenticateAuthRoute";
import Users from "../../DemoPages/Users";
import VerifyOTP from "../../DemoPages/UserPages/VerifyOTP";
import ResetPassword from "../../DemoPages/UserPages/ResetPassword";
import { toast } from "react-toastify/dist";
import "../../assets/css/general.css";
import "../../common/css/commonTable.css"
import UserProfile from "../../DemoPages/Users/UserProfile";
import axios from "axios";
import AdminMaster from "../../DemoPages/AdminMaster";
import AddAdmin from "../../DemoPages/AdminMaster/AddAdmin";
import EditAdmin from "../../DemoPages/AdminMaster/EditAdmin";
import Chat from "../../DemoPages/Applications/Chat";
import ChatLayout from "../ChatLayout";
import Loader from "../../components/Loader";
import { loaderContext } from "../../config/context";
import { getNotificationToken, onMessageListener } from "../../firebase";
import GolfCourse from "../../DemoPages/GolfCourse"


const AppMain = () => {
    const [isLoading, setIsLoading] = useState(false)
    // const [show, setShow] = useState(false);
    // const [notification, setNotification] = useState({ title: '', body: '' });
    const [token, setTokenFound] = useState("");
    useEffect(() => {
        getNotificationToken(setTokenFound);
    }, [])
    onMessageListener()
    // onMessageListener().then(payload => {
    //     setShow(true);
    //     setNotification({ title: payload.notification.title, body: payload.notification.body })
    //     console.log(payload);
    // }).catch(err => console.log('failed: ', err));

    const authToken = localStorage.getItem("authToken");
    const navigate = useNavigate()
    console.log("Auth Token", authToken)
    axios.interceptors.response.use(null, error => {
        if (error.response.status == 401) {
            localStorage.removeItem('authToken');
            localStorage.removeItem("userType");
            navigate("/login", { replace: true })
            return Promise.reject(error);
        }
        return Promise.reject(error);
    });

    return (
        <Fragment>
            {isLoading && <Loader />}
            <loaderContext.Provider value={{ isLoading, setIsLoading }}>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">

                            </div>
                            {/* <h6 className="mt-3">
                            Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6> */}
                        </div>
                    </div>
                } >
                    <Routes>
                        <Route element={<AuthenticateRoute />}>
                            <Route element={<MainLayout />}>
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="users" element={<Users />} />
                                <Route path="user-profile" element={<UserProfile />} />
                                <Route path="admin-master" element={<AdminMaster />} />
                                <Route path="add-admin" element={<AddAdmin />} />
                                <Route path="edit-admin" element={<EditAdmin />} />
                                <Route path="golf-course" element={<GolfCourse />} />
                                <Route path="*" element={<Navigate to="/dashboard" replace />} />
                            </Route>
                            <Route element={<ChatLayout />}>
                                <Route path="chat-support" element={<Chat />} />
                            </Route>
                        </Route>
                        <Route element={<AuthenticateAuthRoute />}>
                            <Route element={<AuthLayout />}>
                                <Route path="*" element={<Navigate to="/login" replace />} />
                                <Route path="login" element={<LoginBoxed />} />
                                <Route path="forgot-password" element={<ForgotPasswordBoxed />} />
                                <Route path="verify-otp" element={<VerifyOTP fcmToken={token} />} />
                                <Route path="reset-password" element={<ResetPassword />} />
                            </Route>
                        </Route>
                    </Routes>
                    <ToastContainer
                        icon={false}
                        position={toast.POSITION.BOTTOM_LEFT}
                    />
                </Suspense>
            </loaderContext.Provider>
        </Fragment>
    )
};

export default AppMain;
