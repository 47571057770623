import React, { Fragment, useContext } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import FullLogo from "../../../assets/utils/images/full-logo.png"
import "../Userpages.css"
import { useLocation, useNavigate } from "react-router-dom";
import { resendOTP, verifyLoginOTP } from "../../../service/authService";
import { toast } from "react-toastify";
import toastConfig from "../../../config/toast-config";
import { verifyOTPForm } from "../../../config/formValidation";
import { errorMessage } from "../../../helpers/catchErrorMessage";
import { onlyNumber } from "../../../config/validationRegex";
import httpServices from "../../../service/httpServices";
import { loaderContext } from "../../../config/context";

export default function VerifyOTP({ fcmToken }) {
    const navigate = useNavigate()
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const location = useLocation();
    const verifyOTP = async (formData) => {
        setIsLoading(true)
        try {
            const userId = localStorage.getItem("userId");
            formData.timeZone = "Asia/Kolkata"
            formData.userId = userId
            formData.language = {
                googleCode: "en",
                _id: "60c8ec749d7df413c5b27245"
            }
            formData.fcmToken = fcmToken
            const { data } = await verifyLoginOTP(formData);
            console.log("Data", data)
            localStorage.setItem("authToken", data?.data?.token)
            localStorage.setItem("themeColor", data?.data?.themeColor)
            localStorage.setItem("timeZone", data?.data?.timeZone)
            localStorage.setItem("userName", `${data?.data?.firstName} ${data?.data?.lastName}`)
            localStorage.setItem("profilePhoto", data?.data?.profilePhoto?.toString())
            localStorage.setItem("language", JSON.stringify(data?.data?.language))
            localStorage.setItem("userType", data?.data?.userType)
            toast.success(data?.message, toastConfig)
            navigate("/dashboard")
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    const resendOtp = async () => {
        setIsLoading(true)
        try {
            const { data } = await resendOTP(location?.state)
            toast.success(data.data.otp, toastConfig)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    const {
        handleSubmit,
        register,
        control,
        formState: { errors }
    } = useForm();
    return (
        <Fragment>
            <div className="app-logo-inverse mx-auto mb-3" />
            <div className="modal-dialog w-100 mx-auto">
                <div className="modal-content">
                    <Form>
                        <div className="d-flex align-items-center justify-content-center w-100">
                            <img src={FullLogo} height="220rem" width="400rem" />
                        </div>
                        <div className="modal-body pt-2">
                            <h2 className="user-page-heading mb-3">Verify OTP</h2>
                            <div className="h5 modal-title text-center">
                                {/* <h4 className="mt-2">
                                <span>Enter OTP.</span>
                            </h4> */}
                            </div>

                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <Controller
                                            name="otp"
                                            control={control}
                                            rules={{ required: true, minLength: 4, pattern: onlyNumber }}
                                            render={({ field: { value, onChange } }) => (
                                                <Input type="text" className={`${errors.otp ? "is-invalid" : ""}`} name="otp" id="otp" size="lg" placeholder="Enter your OTP" value={value} onChange={onChange} maxlength="4" />
                                            )}
                                        />
                                        {errors.otp && (
                                            <small className="text-danger">
                                                {errors.otp.type === 'required' && (
                                                    <span>
                                                        {verifyOTPForm.otpReq}
                                                    </span>
                                                )}
                                                {errors.otp.type === 'minLength' && (
                                                    <span>
                                                        {verifyOTPForm.otpInvalid}
                                                    </span>
                                                )}
                                                {errors.otp.type === 'pattern' && (
                                                    <span>
                                                        {verifyOTPForm.otpInvalid}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <a className="text-primary text-decoration-underline" style={{ width: "fit-content", }} onClick={() => resendOtp()}>Resend OTP</a>
                            </Row>
                            {/* <div className="d-flex justify-content-end">
                                        <div className="btn text-primary">
                                            <a onClick={() => navigate("/login")}>
                                                Back To Sign In
                                            </a>
                                        </div>
                                    </div> */}
                        </div>
                        <div className="modal-footer clearfix">
                            <div className="w-100">
                                <Button color="primary" type="submit" className="d-flex w-100 justify-content-center" style={{ color: "#ffffff" }} onClick={handleSubmit(verifyOTP)} size="lg">
                                    <div className="fs-5">VERIFY OTP</div>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Fragment>
    )
}
