import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

export default function ConsentDetails({ userData }) {
    const requiredData = [
        { label: "I agree to the Greensboro National Golf Course Club privacy policy", value: "privacyPolicy" },
        { label: "I accept and understand the terms of service", value: "termsOfService" },
        { label: "I accept and understand the principles of ethics for services", value: "principlesOfEthics" }
    ]
    const optionData1 = [
        { label: "SMS", value: "sms" },
        { label: "E-mail", value: "email" },
        { label: "Phone", value: "phone" },
        { label: "In App Notification", value: "inAppNotification" },
    ]
    const optionData2 = [
        { label: "SMS", value: "sms" },
        { label: "E-mail", value: "email" },
        { label: "Phone", value: "phone" },
        { label: "In App Notification", value: "inAppNotification" },
    ]
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                CONSENT DETAILS
            </div>
            <div>
                <div className='mt-3' style={{ color: "black", fontWeight: "500" }}>
                    REQUIRED: I certify that the information provided is true and correct under penalty of law.
                </div>
                <div className='px-3 pb-3 pt-1 mt-2' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                    {requiredData.map((conf) => (
                        <div className='mt-3'>
                            <FormGroup check >
                                <Input type="checkbox" style={{ marginTop: "6px" }} checked={userData?.consent?.required[conf.value]} />
                                <Label check>
                                    <div style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>{conf.label}</div>
                                </Label>
                            </FormGroup>
                        </div>
                    ))}
                </div>
                <div className='mt-3' style={{ color: "black", fontWeight: "500" }}>
                    OPTIONAL: I would line to receive news, updates, and promotions.
                </div>
                <div className='px-3 pb-3 pt-1 mt-2' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                    {optionData1.map((conf) => (
                        <div className='mt-3'>
                            <FormGroup check >
                                <Input type="checkbox" style={{ marginTop: "6px" }} checked={userData?.consent?.promotions[conf.value]} />
                                <Label check>
                                    <div style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>{conf.label}</div>
                                </Label>
                            </FormGroup>
                        </div>
                    ))}
                </div>
                <div className='mt-3' style={{ color: "black", fontWeight: "500" }}>
                    OPTIONAL: I would like to receive opportunities to earn extra income through various revenue.
                </div>
                <div className='px-3 pb-3 pt-1 mt-2' style={{ backgroundColor: "white", borderRadius: "5px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                    {optionData2.map((conf) => (
                        <div className='mt-3'>
                            <FormGroup check >
                                <Input type="checkbox" style={{ marginTop: "6px" }} checked={userData?.consent?.revenue[conf.value]} />
                                <Label check>
                                    <div style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>{conf.label}</div>
                                </Label>
                            </FormGroup>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
