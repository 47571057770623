const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = "https://192.168.1.13:9101/api";
const baseUrl2 = "https://eaqsjflcmb.execute-api.us-east-1.amazonaws.com/Dev"

export const commonEndpoints = {};

export const authEndpoints = {
  login: `${baseUrl}/admin/login`,
  verifyOTP: `${baseUrl}/admin/verifyOtp`,
  resendOTP: `${baseUrl}/admin/resendOtp`
};

export const userEndPoints = {
  getUsers: `${baseUrl}/admin/userlist`,
  suspendUser: `${baseUrl}/admin/user/suspend`,
  activateUser: `${baseUrl}/admin/user/activate`,
  deleteUser: `${baseUrl}/admin/user/delete`,
  getUserById: `${baseUrl}/admin/user`
}

export const configEndPoint = {
  getCountryCodes: `${baseUrl}/admin/countrylist`
}

export const masterAdminEndPoint = {
  listMasterAdmin: `${baseUrl}/admin/adminlist`,
  getMasterAdmin: `${baseUrl}/admin/getadmin`,
  addMasterAdmin: `${baseUrl}/admin/add`,
  editMasterAdmin: `${baseUrl}/admin/update`,
  suspendMasterAdmin: `${baseUrl}/admin/suspend`,
  activateMasterAdmin: `${baseUrl}/admin/activate`,
  deleteMasterAdmin: `${baseUrl}/admin/delete`
}
export const chatEndPoint = {
  getChannelList: `${baseUrl}/admin/chats/list`,
  getChatConversasion: `${baseUrl}/admin/chats/converstation`,
  endChatConverstion: `${baseUrl}/admin/chats/contact/update`,
  readChat: `${baseUrl}/admin/chats/message/status/read`
}

export const golfCourseEndPoint = {
  getCourse: `${baseUrl2}/course/get`
}