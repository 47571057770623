import httpServices from "./httpServices";
import { userEndPoints } from "./config";

export const getUserList = async () => {
    return await httpServices.get(userEndPoints.getUsers)
}
export const suspendUser = async (userId) => {
    return await httpServices.put(`${userEndPoints.suspendUser}/${userId}`)
}

export const activateUser = async (userId) => {
    return await httpServices.put(`${userEndPoints.activateUser}/${userId}`)
}

export const deleteUser = async (userId) => {
    return await httpServices.delete(`${userEndPoints.deleteUser}/${userId}`)
}

export const getUserDataById = async (userId) => {
    return await httpServices.get(`${userEndPoints.getUserById}/${userId}`)
}