import httpServices from "./httpServices";
import { authEndpoints } from "./config";

export const loginUser = async (data) => {
    return await httpServices.post(authEndpoints.login, data)
}

export const verifyLoginOTP = async (data) => {
    return await httpServices.post(authEndpoints.verifyOTP, data)
}

export const resendOTP = async (data) => {
    return await httpServices.post(authEndpoints.resendOTP, data)
}