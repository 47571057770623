import React, { useState } from 'react'
import Tshirt from "../../assets/icons/black_golf_tshirt.svg"
import Shoes from "../../assets/icons/black_golf_shoes.svg"
import Pant from "../../assets/icons/black_golf_pant.svg"
import Gloves from "../../assets/icons/black_golf_gloves.svg"
import GolfBag from "../../assets/icons/black_golf_bag.svg"
import GolfBall from "../../assets/icons/black_golf_balls.svg"
import Golf from "../../assets/icons/golf.svg"

export default function GolfPreferences({ userData }) {
    const [currentTab, setCurrentTab] = useState("course")
    const tabs = [
        { label: "COURSE", value: "course" },
        { label: "CLOTHING", value: "clothing" },
        { label: "EQUIPMENT", value: "equipment" }
    ]
    const clothingData = [
        { label: "GOLF SHIRT SIZE", value: "shirtSize", icon: Tshirt },
        { label: "GOLF PENTS SIZE", value: "pantSize", icon: Pant },
        { label: "GOLF SHOES SIZE", value: "shoesSize", icon: Shoes },
        { label: "GOLF GLOVE SIZE", value: "gloveSize", icon: Gloves },
    ]
    const equipments = [
        { label: "GOLF CLUBS", value: "golfClubs", icon: Golf },
        { label: "GOLF BAGS", value: "golfBags", icon: GolfBag },
        { label: "GOLF BALLS", value: "golfBags", icon: GolfBall }
    ]
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                GOLF PREFERENCES
            </div>
            <div className='p-3 mt-3' style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                <div className='d-flex flex-wrap justify-content-between align-items-center'>
                    {tabs.map((tab, index) => (
                        <div key={index} className={`text-center py-2 ${tab?.value === currentTab ? "gradiant-green" : ""}`} style={{ boxShadow: tab?.value != currentTab ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", borderRadius: "10px", minWidth: "30%", cursor: "pointer" }} onClick={() => setCurrentTab(tab?.value)}>
                            <div style={{ fontWeight: "bold", color: tab?.value == currentTab ? "white" : "black" }}>{tab?.label}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='mt-3' style={{ backgroundColor: "white", borderRadius: "10px" }}>
                {
                    currentTab === tabs[0]?.value && <div>
                        {userData?.golfPreference?.courses?.length > 0 ?
                            <div>
                                {userData?.golfPreference?.courses?.map((course, index) => (
                                    <div className={`p-3 ${index > 0 && "mt-3"}`} style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                                        <div className='ms-1'>
                                            <div style={{ fontWeight: "bold", fontSize: "17px" }}>{course?.name}</div>
                                            <div style={{ fontWeight: "600" }}>{course?.location}</div>
                                        </div>
                                        <div className='d-flex mt-3 justify-content-between' style={{ backgroundColor: "white", borderRadius: "10px", width: "100%" }}>
                                            <div style={{ minWidth: "32%" }}>
                                                <div className='gradiant-orange py-2 px-3 text-center' style={{ borderRadius: "10px" }}>
                                                    <div style={{ color: "white", fontWeight: "600" }}>PLAYED</div>
                                                    <div style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>{course?.score?.played}</div>
                                                </div>
                                            </div>
                                            <div style={{ minWidth: "32%" }}>
                                                <div className='gradiant-green py-2 px-3 text-center' style={{ borderRadius: "10px" }}>
                                                    <div style={{ color: "white", fontWeight: "600" }}>AVERAGE</div>
                                                    <div style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>{course?.score?.average}(+{course?.score?.roundOff})</div>
                                                </div>
                                            </div>
                                            <div style={{ minWidth: "32%" }}>
                                                <div className='gradiant-blue py-2  text-center' style={{ borderRadius: "10px" }}>
                                                    <div style={{ color: "white", fontWeight: "600" }}>GHIN HANDICAP</div>
                                                    <div style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>{course?.score?.handicap}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> : <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                                No Data Found
                            </div>}
                    </div>
                }
                {
                    currentTab === tabs[1]?.value &&
                    <div>
                        {clothingData?.map((course, index) => (
                            <div className={`p-3 d-flex justify-content-between  ${index > 0 && "mt-3"}`} style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                                <div className='col-3'>
                                    <img src={course?.icon} height="50rem" width="50rem" />
                                </div>
                                <div className='col-6' style={{ fontWeight: "bold", fontSize: "18px" }}>{course?.label}</div>
                                <div className='col-3'>
                                    <div><span style={{ fontWeight: "bold", color: "black" }}>U.S.</span> {userData?.golfPreference?.clothing[course?.value]?.allSize?.US}</div>
                                    <div><span style={{ fontWeight: "bold", color: "black" }}>U.K.</span> {userData?.golfPreference?.clothing[course?.value]?.allSize?.UK}</div>
                                    <div><span style={{ fontWeight: "bold", color: "black" }}>E.U.</span> {userData?.golfPreference?.clothing[course?.value]?.allSize?.EU}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {
                    currentTab === tabs[2]?.value &&
                    <div>
                        {equipments?.map((course, index) => (
                            <div className={`p-3 d-flex justify-content-between  ${index > 0 && "mt-3"}`} style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" }}>
                                <div className='col-3'>
                                    <img src={course?.icon} height="50rem" width="50rem" />
                                </div>
                                <div className='col-6'>
                                    <div style={{ fontWeight: "bold", fontSize: "18px" }}>{course?.label}</div>
                                    {userData?.golfPreference?.equipment[course?.value]?.list.map((list, index) => (
                                        <div style={{ color: "black" }} className='d-flex col-12'>
                                            <div className='col-6' style={{ fontWeight: "bold" }}>{list?.type}:</div>
                                            <div className='col-6'>{list?.brand}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className='col-3'>
                                    {userData?.golfPreference?.equipment[course?.value]?.side}
                                </div>
                            </div>
                        ))}

                    </div>
                }
            </div>
        </div>
    )
}
