import React, { Fragment, Component, useState, useEffect, useCallback, useRef, useContext, useReducer } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sweetalertConfig from '../../../config/sweetalert-config.js';
import "./chat.css"

import {
  Button,
  Card,
  Nav,
  NavItem,
  InputGroup,
  Input,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Label,
  FormGroup,
} from "reactstrap";

import cx from "classnames";
import { Elastic } from "react-burgers";
import Avatar from "../../../assets/icons/Avatar.svg"
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg"
import ChatSupport from "../../../assets/images/ic_support.png"

import avatar1 from "../../../assets/utils/images/avatars/2.jpg";
import avatar2 from "../../../assets/utils/images/avatars/2.jpg";

import {
  faCog,
  faCalendarAlt,
  faSearch,
  faCross,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import { errorMessage } from "../../../helpers/catchErrorMessage";
import { endChatConverstion, getChannelList, getChatConversasion, readUnreadChat } from "../../../service/chatService.js";
import moment from "moment";
import socket from "../../../config/socket.js";
import { toast } from "react-toastify";
import toastConfig from "../../../config/toast-config.js";
import { ReactComponent as Eye } from "../../../assets/icons/view.svg";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as Close } from "../../../assets/icons/close.svg"
import { Link, NavLink, useNavigate } from "react-router-dom";
import { loaderContext } from "../../../config/context.js";
import Loader from "../../../components/Loader.js";
import PageTitleAlt from "../../../Layout/AppMain/PageTitleAlt.js";
import { useSelector } from "react-redux";

export default function Chat({ props }) {
  const store = useSelector(state => state?.ThemeOptions)
  const [active, setActive] = useState(false);
  const [queryString, setQueryString] = useState("")
  const [chatQuearyString, setChatQuearyString] = useState("")
  const [chatTab, setChatTab] = useState("openChat")
  const [allChannel, setAllChannel] = useState([]);
  const [filteredAllChannel, setFilteredAllChannel] = useState([]);
  const [currentChannel, setCurrentChannel] = useState({});
  const [currentChannelId, setCurrentChannelId] = useState("");
  const [sortType, setSortType] = useState("");
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const [isLoaderShow, setIsLoaderShow] = useState(false)
  const [isFilterShow, setIsFilterShow] = useState(false)
  const [currentChatConversation, setCurrentChatConversation] = useState([]);
  const [filterCurrentChatConversation, setFilterCurrentChatConversation] = useState([]);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [isChannelSearch, setIsChannelSearch] = useState(false)
  const [userId, setUserId] = useState("")
  const [message, setMessage] = useState("")
  const profilePhoto = localStorage.getItem("profilePhoto")
  const navigate = useNavigate();
  const chatEnd = useRef(null)

  const getListOfAllChannel = async () => {
    setIsLoading(true)
    try {
      const userId = localStorage.getItem("userId")
      const { data } = await getChannelList(userId);
      setAllChannel(data.data)
      setFilteredAllChannel(data?.data?.filter((channel) => `${channel.firstName} ${channel.lastName}`.toLowerCase().includes(queryString.toLowerCase())).filter((event) => { return chatTab === "openChat" ? event?.isActive : !event?.isActive }))
      setUserId(userId)
    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }
  const scrollTobottom = () => {
    chatEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }
  const getAllChatListByChannelId = async () => {
    setIsLoaderShow(true)
    try {
      const { data } = await getChatConversasion(currentChannelId);
      setCurrentChatConversation(data?.data)
      setFilterCurrentChatConversation(data?.data)
    } catch (error) {
      errorMessage(error)
    }
    setIsLoaderShow(false)
    scrollTobottom()
  }
  useEffect(() => {
    getListOfAllChannel()
  }, [])
  useEffect(() => {
    setFilteredAllChannel(sortArray(allChannel.filter((channel) => `${channel.firstName} ${channel.lastName}`.toLowerCase().includes(queryString.toLowerCase())).filter((event) => { return chatTab === "openChat" ? event.isActive : !event.isActive }), sortType))
  }, [queryString, chatTab])

  useEffect(() => {
    if (chatQuearyString && chatQuearyString != "") {
      setFilterCurrentChatConversation(currentChatConversation.filter((chatConversation) => chatConversation?.message.toLowerCase().includes(chatQuearyString.toLowerCase())))
    }
  }, [chatQuearyString])
  const readChat = async () => {
    try {
      const { data } = await readUnreadChat({
        contactId: currentChannelId
      })
    } catch (error) {
      errorMessage(error)
    }
  }
  useEffect(() => {
    if (currentChannelId != "") {
      getAllChatListByChannelId()
      setIsSearchBarVisible(false);
      setChatQuearyString("")
      setCurrentChatConversation([])
      setFilterCurrentChatConversation([])
      readChat()
    }
  }, [currentChannelId])
  const getArrangedChatList = (chatList, i) => {
    console.log("ChatList===>", chatList, i)
    const changeData = chatList[i]
    const filteredData = chatList.filter((d, index) => index != i);
    console.log("Channel", [changeData, ...filteredData])
    return []
  }
  useEffect(() => {
    socket.on('get_message', (data) => {
      console.log("New Chat", data?.contactId, currentChannel._id)
      socket.emit('get_channels', {});
      console.log("called get_channels")
      if (data?.contactId == currentChannel._id && !Array.isArray(data)) {
        const newChatData = [...currentChatConversation, data]
        const newFilterChatData = [...filterCurrentChatConversation, data]
        if (currentChatConversation[currentChatConversation.length - 1]?.systemMessageTitle === "CHAT ENDED") {
          const updatedChannel = { ...currentChannel }
          updatedChannel.isActive = true
          setCurrentChannel(updatedChannel)
          setAllChannel(allChannel.map((channel) => {
            if (channel._id === currentChannel._id) {
              channel.isActive = true
            }
            return channel
          }))
          setChatTab("openChat")
        }
        setCurrentChatConversation(newChatData)
        setFilterCurrentChatConversation(newFilterChatData)
        // scrollTobottom()
      }
    });
    socket.on('get_channels', (data) => {
      console.log("got response in get_channels")
      console.log("Channel", data)
      // setCurrentChannel(data)
      setAllChannel(data)
      setFilteredAllChannel(sortArray(data.filter((channel) => `${channel.firstName} ${channel.lastName}`.toLowerCase().includes(queryString.toLowerCase())).filter((event) => { return chatTab === "openChat" ? event.isActive : !event.isActive }), sortType))
      setChatTab("openChat")
    });
    scrollTobottom()
    return () => {
      socket.off("get_message")
    }
  })
  const sortArray = (data, sorting) => {
    if (sortType != "") {
      if (sorting == "asc") {
        return data.sort(function (a, b) {
          // here a , b is whole object, you can access its property
          //convert both to lowercase
          let x = `${a?.firstName} ${a?.lastName}`.toLowerCase();
          let y = `${b?.firstName} ${b?.lastName}`.toLowerCase();

          //compare the word which is comes first
          if (x > y) { return 1; }
          if (x < y) { return -1; }
          return 0;
        });
      } else {
        return data.sort(function (a, b) {
          // here a , b is whole object, you can access its property
          //convert both to lowercase
          let x = `${a?.firstName} ${a?.lastName}`.toLowerCase();
          let y = `${b?.firstName} ${b?.lastName}`.toLowerCase();

          //compare the word which is comes first
          if (x > y) { return -1; }
          if (x < y) { return 1; }
          return 0;
        });
      }
    } else {
      return data
    }

  }
  useEffect(() => {
    if (sortType != "") {
      setFilteredAllChannel(sortArray(allChannel.filter((channel) => `${channel.firstName} ${channel.lastName}`.toLowerCase().includes(queryString.toLowerCase())).filter((event) => { return chatTab === "openChat" ? event?.isActive : !event?.isActive }), sortType))
    } else {
      setFilteredAllChannel(allChannel.filter((channel) => `${channel.firstName} ${channel.lastName}`.toLowerCase().includes(queryString.toLowerCase())).filter((event) => { return chatTab === "openChat" ? event?.isActive : !event?.isActive }))
    }
  }, [sortType])

  const sendMessage = () => {
    if (message.trim()) {
      socket.emit('send_message', {
        message,
        contactId: currentChannelId,
        type: "text",
        senderId: "63dd57b6779928001152c4dc",
        receiverId: currentChannel?.chatUserId === "63dd57b6779928001152c4dc" ? currentChannel?.chatContactUserId : currentChannel?.chatUserId
      }, response => {
        // if (response?.status) {
        //   const newChatData = [...currentChatConversation, response?.data]
        //   const newFilterChatData = [...filterCurrentChatConversation, response?.data]
        //   setCurrentChatConversation(newChatData)
        //   setFilterCurrentChatConversation(newFilterChatData)
        // }
        scrollTobottom()
      });
      setMessage("");
    } else {
      toast.error("Please write something in message.", toastConfig)
    }
  }
  const endChat = () => {
    Swal.fire({
      title: "End Chat",
      text: "Are you sure you want to close this conversation?",
      icon: 'warning',
      ...sweetalertConfig.confirmation,
      confirmButtonText: "End Chat"
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const { data } = await endChatConverstion({
            contactId: currentChannelId,
            isActive: false
          })
          const updatedChannel = { ...currentChannel }
          updatedChannel.isActive = false
          setCurrentChannel(updatedChannel)
          setAllChannel(allChannel.map((channel) => {
            if (channel._id === currentChannel._id) {
              channel.isActive = false
            }
            return channel
          }))
          // getListOfAllChannel()
          Swal.fire({
            icon: 'success',
            title: "Chat End",
            text: "Chat ended successfully.",
            ...sweetalertConfig.simple
          });
          setChatTab("Golfers")
        } catch (e) {
          errorMessage(e)
        }
      }
    });
  }
  const getActiveChatUnreadCount = () => {
    let count = 0;
    allChannel.map((channel) => {
      if (channel?.isActive) {
        count += channel?.unreadCount
      }
    })
    return count
  }

  return (

    <>
      <div className={`mb-2 ${store?.enableClosedSidebar ? "chat-top-bar-close-side" : "chat-top-bar"}`} style={{ position: 'fixed', zIndex: 9999, backgroundColor: "#e9ecef" }}>
        <PageTitleAlt heading="GOLFERS SUPPORT CHAT"
          paddingValue="1rem 1.5rem"
          margin="0"
          marginTop="-1.5rem"
          marginBottom="0.5rem"
          // subheading="Chat support for users."
          // icon={<img src={WhiteChat} style={{ height: "100%", width: "100%", fill: "white" }} />}
          icon={<i class="metismenu-icon pe-7s-chat" style={{ color: "white", height: "100%", width: "100%" }}></i>}
          button={
            (currentChannelId && currentChannelId != "") && <UncontrolledButtonDropdown>
              <DropdownToggle caret color="primary" className="ms-2">
                <span className="opacity-7 me-1">
                  <FontAwesomeIcon icon={faCog} color="#ffffff" />
                </span>
                <span color="#000000">Actions</span>
              </DropdownToggle>
              <DropdownMenu rigth={false} flip={true}>
                <div className="px-3 py-1">
                  <div className="d-flex flex-wrap align-items-center" style={{ cursor: "pointer" }} onClick={() => navigate(`/user-profile`, { state: { userId: currentChannel?.userId, backUrl: "/chat-support" } })}>
                    <Eye height={20} width={20} fill="#000000" />
                    <div className="ms-2" style={{ fontWeight: "600", color: "black" }} >View</div>
                  </div>
                  <Link
                    to='#'
                    onClick={(e) => {
                      window.location.href = 'mailto:support@example.com';
                      e.preventDefault();
                    }}
                  >
                    <div className="d-flex flex-wrap align-items-center pt-2" style={{ cursor: "pointer" }}>
                      <Email height={20} width={20} />
                      <div className="ms-2" style={{ fontWeight: "600", color: "black" }}>Email</div>
                    </div>
                  </Link>
                  {currentChannel?.isActive == 1 && <div className="d-flex flex-wrap align-items-center pt-2" style={{ cursor: "pointer" }} onClick={() => endChat()}>
                    <Close height={20} width={20} />
                    <div className="ms-2" style={{ fontWeight: "600", color: "black" }}>End Chat</div>
                  </div>}
                </div>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          }
        />
        <div className="d-flex w-100 bg-white align-items-center col-12">
          <div className="bg-primary" style={{ minWidth: "22.5rem", height: "90%", borderRight: "2px solid #e9ecef" }}>
            <div className="d-flex pt-2 ps-2 pe-2 pb-2 bg-white">
              <div className="d-flex align-items-center justify-content-between w-100" style={{ height: "100%" }}>
                <div style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center", minWidth: "8rem" }}>
                  <div className={` ${chatTab === "openChat" ? "bg-primary text-white" : ""}`} style={{ boxShadow: chatTab != "openChat" ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", display: 'flex', flexWrap: "wrap", fontWeight: "bold", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "fit-content", borderRadius: "5px", minWidth: "100%", padding: "0.5rem 0" }} onClick={() => setChatTab("openChat")}>
                    OPEN CHATS
                    {getActiveChatUnreadCount() != 0 && <div style={{ position: "absolute", top: "55px", left: "120px" }}>
                      <div className="bg-danger d-flex align-items-center justify-content-center" style={{ minWidth: "20px", minHeight: "20px", borderRadius: "100%" }}>
                        <div className="text-white" style={{ fontSize: "10px", fontWeight: "bold" }}>{getActiveChatUnreadCount()}</div>
                      </div>
                    </div>}
                  </div>
                </div>
                <div style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center", minWidth: "8rem" }}>
                  <div className={` ${chatTab === "Golfers" ? "bg-primary text-white" : ""}`} style={{ boxShadow: chatTab != "Golfers" ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", display: 'flex', flexWrap: "wrap", fontWeight: "bold", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "fit-content", borderRadius: "5px", minWidth: "100%", padding: "0.5rem 0" }} onClick={() => setChatTab("Golfers")}>GOLFERS</div>
                </div>
                <Button className="btn-icon btn-icon-only btn-shadow btn-outline-2x " outline color="secondary" onClick={() => setIsChannelSearch(!isChannelSearch)}>
                  <FontAwesomeIcon icon={faSearch} size={10} />
                </Button>
              </div>
            </div>
            {isChannelSearch && <div className="px-2 py-2" style={{ backgroundColor: "white" }}>
              <InputGroup>
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <Input placeholder="Search..." value={queryString} onChange={(e) => setQueryString(e.target.value)} />
              </InputGroup>
            </div>}
          </div>
          {(currentChannelId && currentChannelId != "") && <div className="app-inner-layout__top-pane w-100 px-3  d-flex align-items-center justify-content-between">
            <div className="pane-left d-flex">
              <div className="mobile-app-menu-btn">
                <Elastic width={26} lineHeight={2} lineSpacing={5} color="white" active={active}
                  onClick={() =>
                    setActive(!active)
                  } />
              </div>
              <div className="avatar-icon-wrapper me-2">
                {currentChannel?.onlineStatus && <div className="badge badge-bottom btn-shine bg-success badge-dot badge-dot-lg" />}
                <div className="avatar-icon avatar-icon-xl rounded">
                  <img width={82} src={(currentChannel?.profileImage && currentChannel?.profileImage != "null") ? currentChannel?.profileImage : Avatar} alt="" />
                </div>
              </div>
              <h5 className="mb-0 text-nowrap">
                {`${currentChannel?.firstName} ${currentChannel?.lastName}`}
                <div className="opacity-7">
                  {currentChannel?.accountType}
                </div>
              </h5>
            </div>
            <div className="d-flex">
              {isSearchBarVisible && <div className="ps-3 pe-3 " style={{ backgroundColor: "white" }}>
                <InputGroup>
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <Input placeholder="Search..." value={chatQuearyString} onChange={(e) => setChatQuearyString(e.target.value)} />
                </InputGroup>
              </div>}
              {/* <Button className="btn" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
              {!isSearchBarVisible ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faTimes} />}
            </Button> */}
              <Button className="btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
                <FontAwesomeIcon icon={faSearch} size={10} />
              </Button>
            </div></div>}
        </div>
      </div>
      <div className={cx("app-inner-layout chat-layout", {
        "open-mobile-menu": active,
      })} style={{ padding: "0px" }}>
        <div className="app-inner-layout__wrapper" style={{ paddingTop: "7.3rem" }}>
          {isLoaderShow ? <Loader /> : (currentChannelId && currentChannelId != "") ? <Card className="app-inner-layout__content" >
            <div className="table-responsive" ref={chatEnd}>
              {/* <div className="app-inner-layout__top-pane chat-top-bar" style={{ position: "fixed", zIndex: 99999, backgroundColor: "white" }}>
                <div className="pane-left">
                  <div className="mobile-app-menu-btn">
                    <Elastic width={26} lineHeight={2} lineSpacing={5} color="white" active={active}
                      onClick={() =>
                        setActive(!active)
                      } />
                  </div>
                  <div className="avatar-icon-wrapper me-2">
                    {currentChannel?.onlineStatus && <div className="badge badge-bottom btn-shine bg-success badge-dot badge-dot-lg" />}
                    <div className="avatar-icon avatar-icon-xl rounded">
                      <img width={82} src={(currentChannel?.profileImage && currentChannel?.profileImage != "null") ? currentChannel?.profileImage : Avatar} alt="" />
                    </div>
                  </div>
                  <h4 className="mb-0 text-nowrap">
                    {`${currentChannel?.firstName} ${currentChannel?.lastName}`}
                    <div className="opacity-7">
                      {currentChannel?.accountType}
                    </div>
                  </h4>
                </div>
                <div className="pane-right">
                  {isSearchBarVisible && <div className="ps-3 pe-3 " style={{ backgroundColor: "white" }}>
                    <InputGroup>
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faSearch} />
                      </div>
                      <Input placeholder="Search..." value={chatQuearyString} onChange={(e) => setChatQuearyString(e.target.value)} />
                    </InputGroup>
                  </div>}
                  <Button className="btn" onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
                    {!isSearchBarVisible ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faTimes} />}
                  </Button>
                </div>
              </div> */}
              <div className="chat-wrapper chat-container pt-0">
                {
                  filterCurrentChatConversation?.map((chat) => (
                    chat?.systemMessageTitle != "CHAT ENDED" ? userId === chat?.senderId ?
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="float-end">
                          <div className="chat-box-wrapper chat-box-wrapper-right">
                            <div>
                              <div className="chat-box">
                                {chat?.message}
                              </div>
                              <small className="opacity-6">
                                <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
                                {`${moment(chat?.createdAt).format("HH:mm")} | ${moment(chat?.createdAt).format("DD MMM YYYY")}`}
                              </small>
                            </div>
                            <div>
                              <div className="avatar-icon-wrapper me-1">
                                <div className="badge badge-bottom btn-shine bg-success badge-dot badge-dot-lg" />
                                <div className="avatar-icon avatar-icon-lg rounded">
                                  <img src={ChatSupport} alt="" height={30} width={30} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      // <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      //   <div className="float-end">
                      <div className="chat-box-wrapper" style={{ width: "100%" }}>
                        <div>
                          <div className="avatar-icon-wrapper ms-1">
                            <div className="badge badge-bottom btn-shine bg-success badge-dot badge-dot-lg" />
                            <div className="avatar-icon avatar-icon-lg rounded">
                              <img src={(currentChannel?.profileImage && currentChannel?.profileImage != "null") ? currentChannel?.profileImage : Avatar} alt="" />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="chat-box">
                            {chat?.message}
                          </div>
                          <small className="opacity-6">
                            <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
                            {`${moment(chat?.createdAt).format("HH:mm")} | ${moment(chat?.createdAt).format("DD MMM YYYY")}`}
                          </small>
                        </div>
                      </div> :
                      <div className="chat-box-wrapper px-0 py-2 mb-3" style={{ width: "100%", backgroundColor: "#F8F8F8" }}>
                        <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                          <hr style={{ width: "100%" }}></hr>
                          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                            <div style={{ width: "fit-content", alignSelf: "center", fontWeight: "bold", fontSize: "13px" }}>{chat?.systemMessageTitle}</div>
                            <div style={{ width: "fit-content", alignSelf: "center", fontWeight: "bold", fontSize: "13px" }}>{`${moment(chat?.createdAt).format("DD MMM YYYY")?.toUpperCase()} | ${moment(chat?.createdAt).format("hh:mm a")}`}</div>
                          </div>
                          <hr style={{ width: "100%" }}></hr>
                        </div>
                      </div>
                    //   </div>
                    // </div>
                  ))
                }
              </div>
              {/* {currentChannel?.isActive == 1 ? */}
              <div className="app-inner-layout__bottom-pane chat-bottom-area" style={{ position: "fixed", bottom: 0, background: "white" }} >
                <div className="d-flex flex-wrap align-items-center gap-3">
                  <form className="d-flex flex-wrap align-items-center gap-3 w-100" >
                    <div className="col-8">
                      <Input bsSize="lg" type="text" placeholder="Type here....." value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                    <div >
                      <Button type="submit" className='btn' color="primary" style={{ color: "#ffffff", maxWidth: "fit-content" }} size="lg" onClick={(e) => { e.preventDefault(); sendMessage() }}>
                        <div style={{ fontSize: "15px" }}>SEND</div>
                      </Button>
                    </div>
                    {currentChannel?.isActive && <div >
                      <Button className='btn' color="danger" style={{ color: "#ffffff", maxWidth: "fit-content" }} size="lg" onClick={() => endChat()}>
                        <div style={{ fontSize: "15px" }}>END CHAT</div>
                      </Button>
                    </div>}
                  </form>
                </div>
              </div>
              {/* :
                <div className="app-inner-layout__bottom-pane d-flex align-items-center justify-content-center chat-bottom-area" style={{ position: "fixed", bottom: 0, background: "white" }} >
                 <p className="h4 text-danger">Conversation was ended.</p>
                 </div>
              } */}
            </div>
          </Card> :
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="d-flex align-items-center justify-content-center" style={{ flexDirection: "column", width: "100%" }}>
                <h3>Welcome</h3>
                <p>You can provide your support from here</p>
              </div>
            </div>}
          <Card className="app-inner-layout__sidebar">
            <div style={{ position: "fixed", height: "100%", width: "360px", overflowY: "auto" }}>
              <Nav vertical>
                {filteredAllChannel?.length > 0 ? filteredAllChannel.map((channel, index) => (
                  <div key={index} >
                    <div style={{ cursor: "pointer" }} className={`dropdown-item ${channel?._id === currentChannelId ? "active" : ""}`} onClick={() => { setCurrentChannel(channel); setCurrentChannelId(channel?._id) }}>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left me-3">
                            <div className="avatar-icon-wrapper">
                              {channel?.onlineStatus && <div className="badge badge-bottom bg-success badge-dot badge-dot-lg" />}
                              <div className="avatar-icon">
                                <img src={(channel?.profileImage && channel?.profileImage != "null") ? channel?.profileImage : Avatar} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="widget-content-left">
                            <div className="widget-heading">
                              {`${channel?.firstName} ${channel?.lastName}`}
                            </div>
                            <div className="widget-subheading">
                              {channel?.accountType}
                            </div>
                          </div>
                          {channel?.unreadCount != 0 && <div className="widget-content-right">
                            <div className="bg-danger d-flex align-items-center justify-content-center" style={{ minWidth: "20px", minHeight: "20px", borderRadius: "100%" }}>
                              <div className="text-white" style={{ fontSize: "10px", fontWeight: "bold" }}>{channel?.unreadCount}</div>
                            </div>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                )) :
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="text-danger">No Chat Found.</div>
                  </div>
                }
              </Nav>
            </div>

            {/* <div className="app-inner-layout__sidebar-footer" style={{
              position: "fixed", width: "inherit", backgroundColor: "white", height: "4.2rem"
            }}>
              <div className="d-flex col-12 align-items-center justify-cotent-center" style={{ borderTop: "#e9ecef solid 1px", borderRight: "#e9ecef solid 1px", height: "100%" }}>
                <div className={`col-6`} style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                  <div className={` ${chatTab === "openChat" ? "bg-primary text-white" : ""}`} style={{ boxShadow: chatTab != "openChat" ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", display: 'flex', flexWrap: "wrap", fontWeight: "bold", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "fit-content", borderRadius: "10px", minWidth: "80%", padding: "0.8rem 0" }} onClick={() => setChatTab("openChat")}>OPEN CHATS</div>
                </div>
                <div className={`col-6`} style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                  <div className={` ${chatTab === "Golfers" ? "bg-primary text-white" : ""}`} style={{ boxShadow: chatTab != "Golfers" ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", display: 'flex', flexWrap: "wrap", fontWeight: "bold", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "fit-content", borderRadius: "10px", minWidth: "80%", padding: "0.8rem 0" }} onClick={() => setChatTab("Golfers")}>GOLFERS</div>
                </div>
              </div>
              <div className="d-flex pt-4 ps-3 pe-3 pb-3" style={{ backgroundColor: "white" }}>
                <InputGroup>
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <Input placeholder="Search..." value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                </InputGroup>
                <Button className='btn px-1 ms-2' color="primary" style={{ color: "#ffffff", maxWidth: "fit-content" }} size="md" onClick={() => setIsFilterShow(!isFilterShow)}>
                  <FilterIcon width={25} height={25} style={{ fill: "#ffffff" }} />
                </Button>
                {isFilterShow && <div className='position-absolute card px-3' style={{ marginTop: "13%", marginLeft: "65%", maxWidth: "10rem", zIndex: "9999" }}>
                  <div className="py-3" style={{ fontSize: "14px", cursor: 'pointer', fontWeight: sortType == "asc" ? "bold" : "600" }} onClick={() => { setSortType("asc"); setIsFilterShow(false) }}>
                    Sort A to Z
                  </div>
                  <div className="pb-3" style={{ fontSize: "14px", cursor: 'pointer', fontWeight: sortType == "desc" ? "bold" : "600" }} onClick={() => { setSortType("desc"); setIsFilterShow(false) }}>
                    Sort Z to A
                  </div>
                  <div className="pb-3 text-danger" style={{ fontSize: "14px", fontWeight: "600", cursor: 'pointer' }} onClick={() => { setSortType(""); setIsFilterShow(false) }}>
                    Reset
                  </div>
                </div>}
              </div>
            </div> */}
            <div className="app-inner-layout__sidebar-footer" style={{
              position: "fixed", width: "inherit", backgroundColor: "white", bottom: 0, height: "5.88rem"
            }}>
              {/* <div className="d-flex col-12 align-items-center justify-cotent-center" style={{ borderTop: "#e9ecef solid 1px", borderRight: "#e9ecef solid 1px", height: "100%" }}>
                <div className={`col-6`} style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                  <div className={` ${chatTab === "openChat" ? "bg-primary text-white" : ""}`} style={{ boxShadow: chatTab != "openChat" ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", display: 'flex', flexWrap: "wrap", fontWeight: "bold", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "fit-content", borderRadius: "10px", minWidth: "80%", padding: "0.8rem 0" }} onClick={() => setChatTab("openChat")}>OPEN CHATS</div>
                </div>
                <div className={`col-6`} style={{ display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
                  <div className={` ${chatTab === "Golfers" ? "bg-primary text-white" : ""}`} style={{ boxShadow: chatTab != "Golfers" ? "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px" : "", display: 'flex', flexWrap: "wrap", fontWeight: "bold", alignItems: "center", justifyContent: "center", cursor: "pointer", width: "fit-content", borderRadius: "10px", minWidth: "80%", padding: "0.8rem 0" }} onClick={() => setChatTab("Golfers")}>GOLFERS</div>
                </div>
              </div> */}
              <div className="d-flex pt-4 ps-1 pe-3 pb-3" style={{ backgroundColor: "white", borderTop: "2px solid #e9ecef", borderRight: "2px solid #e9ecef" }}>
                {/* <InputGroup>
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <Input placeholder="Search..." value={queryString} onChange={(e) => setQueryString(e.target.value)} />
                </InputGroup> */}
                {/* <Button className='btn px-1 ms-2' color="primary" style={{ color: "#ffffff", maxWidth: "fit-content" }} size="md" onClick={() => setIsFilterShow(!isFilterShow)}>
                  <FilterIcon width={25} height={25} style={{ fill: "#ffffff" }} />
                </Button> */}
                <Button className="d-flex justify-content-center btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => setIsFilterShow(!isFilterShow)}>
                  {/* <img src={FilterIcon} height={20} width={20} style={{ fill: "#00000" }} /> */}
                  <FilterIcon height={20} width={20} className="filter-btn" />
                </Button>
                {isFilterShow && <div className='position-absolute card px-3' style={{ marginTop: "-40%", marginLeft: "5%", maxWidth: "10rem", zIndex: "9999" }}>
                  <div className="py-3" style={{ fontSize: "14px", cursor: 'pointer', fontWeight: sortType == "asc" ? "bold" : "600" }} onClick={() => { setSortType("asc"); setIsFilterShow(false) }}>
                    Sort A to Z
                  </div>
                  <div className="pb-3" style={{ fontSize: "14px", cursor: 'pointer', fontWeight: sortType == "desc" ? "bold" : "600" }} onClick={() => { setSortType("desc"); setIsFilterShow(false) }}>
                    Sort Z to A
                  </div>
                  <div className="pb-3 text-danger" style={{ fontSize: "14px", fontWeight: "600", cursor: 'pointer' }} onClick={() => { setSortType(""); setIsFilterShow(false) }}>
                    Reset
                  </div>
                </div>}
              </div>
            </div>
          </Card>
        </div >
      </div >

    </ >

  );
}
