import React, { useContext } from 'react'
import PageTitleAlt from '../../Layout/AppMain/PageTitleAlt';
import Edit from "../../assets/icons/white_edit.svg"
import { useState } from 'react';
import { useEffect } from 'react';
import { errorMessage } from '../../helpers/catchErrorMessage';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import BackIcon from "../../assets/icons/back.svg"
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { alphabetWithSpace, email, onlyNumber } from '../../config/validationRegex';
import { addAdmin } from '../../config/formValidation';
import PhoneNumber from '../../components/PhoneNumber';
import { getCountryCode } from '../../service/configService';
import { toast } from 'react-toastify';
import toastConfig from '../../config/toast-config';
import { getMasterAdminById, updateMasterAdmin } from '../../service/masterAdminService';
import { loaderContext } from '../../config/context';

export default function EditAdmin() {
    const [usersData, setUsersData] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext);
    const navigate = useNavigate()
    const location = useLocation();
    const [countryCode, setCountryCode] = useState({})
    const [countryCodes, setCountryCodes] = useState([]);
    useEffect(async () => {
        setIsLoading(true)
        try {
            const { data: adminData } = await getMasterAdminById(location?.state?.userId)
            const { data } = await getCountryCode();
            const { firstName, lastName, phoneCode, email, phoneNumber } = adminData.data[0];
            setCountryCodes(data?.data)
            setCountryCode(data?.data?.find((country) => country.countryCode === phoneCode))
            reset({ firstName, lastName, phoneNumber, email })
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])
    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: { errors }
    } = useForm();

    const onEditAdminData = async (formData) => {
        setIsLoading(true)
        try {
            formData._id = location?.state?.userId;
            formData.phoneCode = countryCode.countryCode
            const { data } = await updateMasterAdmin(formData)
            console.log("FormData", formData)
            toast.success(data.message, toastConfig)
            navigate("/admin-master", { replace: true })
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    return (
        <>
            <PageTitleAlt heading="Edit Admin"
                subheading="You can edit admin here."
                icon={<img src={Edit} style={{ height: "100%", width: "100%", fill: "white" }} />}
                button={<div className='btn text-primary d-flex' onClick={() => navigate('/admin-master', { replace: true })}><img src={BackIcon} style={{ height: "30%", width: "30%" }} /></div>}
            />
            <div className="row mb-3 g-3">
                <div className="card overflow-hidden mb-3" style={{ minHeight: "25rem" }}>
                    <div className="card-body pt-3">
                        <Form>
                            <div className="modal-body pt-2">
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>First Name <span className="text-danger ms-1">*</span></Label>
                                            <Controller
                                                name="firstName"
                                                control={control}
                                                rules={{ required: true, pattern: alphabetWithSpace }}
                                                render={({ field: { value, onChange } }) => (
                                                    <Input type="text" className={`${errors.firstName ? "is-invalid" : ""}`} name="firstName" id="firstName" size="lg" placeholder="Enter your First Name" value={value} onChange={onChange} />
                                                )}
                                            />
                                            {errors.firstName && (
                                                <small className="text-danger">
                                                    {errors.firstName.type === 'required' && (
                                                        <span>
                                                            {addAdmin.firstNameReq}
                                                        </span>
                                                    )}
                                                    {errors.firstName.type === 'pattern' && (
                                                        <span>
                                                            {addAdmin.firstNameInvalid}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Last Name <span className="text-danger ms-1">*</span></Label>
                                            <Controller
                                                name="lastName"
                                                control={control}
                                                rules={{ required: true, pattern: alphabetWithSpace }}
                                                render={({ field: { value, onChange } }) => (
                                                    <Input type="text" className={`${errors.lastName ? "is-invalid" : ""}`} name="lastName" id="lastName" size="lg" placeholder="Enter your Last Name" value={value} onChange={onChange} />
                                                )}
                                            />
                                            {errors.lastName && (
                                                <small className="text-danger">
                                                    {errors.lastName.type === 'required' && (
                                                        <span>
                                                            {addAdmin.lastNameReq}
                                                        </span>
                                                    )}
                                                    {errors.lastName.type === 'pattern' && (
                                                        <span>
                                                            {addAdmin.lastNameInvalid}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Phone Number <span className="text-danger ms-1">*</span></Label>
                                            <Controller
                                                name="phoneNumber"
                                                control={control}
                                                rules={{ required: true, minLength: 9, pattern: onlyNumber }}
                                                render={({ field: { value, onChange } }) => (
                                                    // <Input type="text" className={`${errors.phoneNumber ? "is-invalid" : ""}`} maxLength="12" name="phoneNumber" id="loginPhone" size="lg" placeholder="Phone Number" value={value} onChange={onChange} />
                                                    <PhoneNumber
                                                        error={errors.phoneNumber ? "is-invalid" : ""}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder="Phone Number"
                                                        countryCode={countryCode}
                                                        setCountryCode={setCountryCode}
                                                        allCountryCodes={countryCodes}
                                                        dropdownDirection="bottom"
                                                        maxLength={12}
                                                    />
                                                )}
                                            />
                                            {errors.phoneNumber && (
                                                <small className="text-danger">
                                                    {errors.phoneNumber.type === 'required' && (
                                                        <span>
                                                            {addAdmin.phoneNoReq}
                                                        </span>
                                                    )}
                                                    {errors.phoneNumber.type === 'minLength' && (
                                                        <span>
                                                            {addAdmin.phoneNoInvalid}
                                                        </span>
                                                    )}
                                                    {errors.phoneNumber.type === 'pattern' && (
                                                        <span>
                                                            {addAdmin.phoneNoInvalid}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Email <span className="text-danger ms-1">*</span></Label>
                                            <Controller
                                                name="email"
                                                control={control}
                                                rules={{ required: true, pattern: email }}
                                                render={({ field: { value, onChange } }) => (
                                                    <Input type="text" className={`${errors.email ? "is-invalid" : ""}`} name="email" id="email" size="lg" placeholder="Enter your Email" value={value} onChange={onChange} />
                                                )}
                                            />
                                            {errors.email && (
                                                <small className="text-danger">
                                                    {errors.email.type === 'required' && (
                                                        <span>
                                                            {addAdmin.emailReq}
                                                        </span>
                                                    )}
                                                    {errors.email.type === 'pattern' && (
                                                        <span>
                                                            {addAdmin.emailInvalid}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className='d-flex w-100 justify-content-end mt-3'>
                                    <Button className='btn' color="primary" style={{ color: "#ffffff", maxWidth: "fit-content" }} size="md" onClick={handleSubmit(onEditAdminData)}>
                                        <div style={{ fontSize: "15px" }}>UPDATE ADMIN</div>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}
