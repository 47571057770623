import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { errorMessage } from '../../helpers/catchErrorMessage';
import { getUserDataById } from '../../service/userService';
import PageTitleAlt from '../../Layout/AppMain/PageTitleAlt';
import UsersIcon from "../../assets/icons/users.svg"
import BackIcon from "../../assets/icons/back.svg"
import avatar from "../../assets/images/avatar.png"
import verify from "../../assets/icons/verify.svg"
import Confirmation from "../../assets/icons/confitmation.svg"
import Contact from "../../assets/icons/contact.svg"
import Credit from "../../assets/icons/credit.svg"
import Emergency from "../../assets/icons/emergency.svg"
import Gallary from "../../assets/icons/gallary.svg"
import Golf from "../../assets/icons/golf.svg"
import Agriment from "../../assets/icons/agriment.svg"
import LeftArrow from "../../assets/icons/left-arrow.svg"
import Complete from "../../assets/icons/complete.svg"
import Whatsapp from "../../assets/icons/whatsapp.svg"
import Facebook from "../../assets/icons/facebook.svg"
import Twitter from "../../assets/icons/twitter.svg"
import Linkdin from "../../assets/icons/linkdin.svg"
import Instagram from "../../assets/icons/instagram.svg"
import "./css/userProfile.css"
import ContactDetails from './ContactDetails';
import moment from 'moment';
import CreditCardDetails from './CreditCardDetails';
import EmergencyContact from './EmergencyContact';
import ConfirmationDetails from './ConfirmationDetails';
import ConsentDetails from './ConsentDetails';
import PhotoDetails from './PhotoDetails';
import GolfPreferences from './GolfPreferences';
import { loaderContext } from '../../config/context';

export default function UserProfile() {
    const [userData, setUsersData] = useState({})
    const [openDetails, setOpenDetails] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const profileMenu = [
        {
            icon: Contact,
            title: "CONTACT",
            subTitle: "Email-Phone-Address",
            onClick: () => console.log("Hello")
        },
        {
            icon: Gallary,
            title: "PHOTO",
            subTitle: "Font and Side",
            onClick: () => console.log("Hello")
        },
        {
            icon: Golf,
            title: "GOLF PREFERENCES",
            subTitle: "",
            onClick: () => console.log("Hello")
        },
        {
            icon: Credit,
            title: "CREDIT / DEBIT CARDS",
            subTitle: "",
            onClick: () => console.log("Hello")
        },
        {
            icon: Emergency,
            title: "EMERGENCY CONTACT",
            subTitle: "",
            onClick: () => console.log("Hello")
        },
        {
            icon: Confirmation,
            title: "CONFIRMATION",
            subTitle: "that data is real and correct",
            onClick: () => console.log("Hello")
        },
        {
            icon: Agriment,
            title: "CONSENT",
            subTitle: "SMS-Email-Phone-Data Pri....",
            onClick: () => console.log("Hello")
        }
    ]
    const setUserData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getUserDataById(location?.state?.userId)
            console.log("User Data", data?.data[0])
            setUsersData(data?.data[0])
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        setUserData()
    }, [])
    return (
        <>
            <PageTitleAlt heading="User Profile"
                subheading="This is the data of user."
                icon={<img src={UsersIcon} style={{ height: "100%", width: "100%", fill: "white" }} />}
                button={<div className='btn text-primary d-flex' onClick={() => navigate(location?.state?.backUrl, { replace: true })}><img src={BackIcon} style={{ height: "30%", width: "30%" }} /></div>}
            />
            <div style={{ height: "100%" }}>
                <div className='bg-page-title-icon p-4' style={{ borderRadius: "10px" }}>
                    <div className='d-flex align-items-center justify-content-center' style={{ height: "100%", flexDirection: "column" }}>
                        <div style={{ backgroundColor: "white", borderRadius: "100%", padding: "3px", zIndex: 500 }}>
                            <img src={userData?.primaryPhotoType && userData?.primaryPhotoType != "" ? userData?.primaryPhotoType == 'front' ? userData?.photoFront ? userData?.photoFront : avatar : userData?.photoSide ? userData?.photoSide : avatar : avatar} height="120rem" style={{ borderRadius: "100%", aspectRatio: "1" }} />
                        </div>
                        <div className='p-3 profile-container' style={{ backgroundColor: "white", borderRadius: "10px", marginTop: "-55px" }}>
                            <div className='d-flex g-3 justify-content-between'>
                                <div style={{ position: "absolute" }}>
                                    <img src={verify} height="30px" />
                                </div>
                                <div className='col-12 pt-5'>
                                    <div className='mb-3 tex-center'>
                                        <div className='text-center'><h5 style={{ fontWeight: "bold", marginBottom: 0, marginRight: "10px" }}>{`${userData.firstName} ${userData.lastName}`}</h5></div>
                                        <div className='text-center'>{userData.userType}</div>
                                    </div>
                                    <div className='d-flex flex-wrap col-12'>
                                        <div className='col-4 text-center'>
                                            <div>
                                                <div>Status</div>
                                                <div style={{ fontWeight: "700" }}>VERIFIED</div>
                                            </div>
                                        </div>
                                        <div className='col-4 text-center'>
                                            <div>
                                                <div>Wallet</div>
                                                <div style={{ fontWeight: "700" }}>$2,586.00</div>
                                            </div>
                                        </div>
                                        <div className='col-4 text-center'>
                                            <div>
                                                <div>Rating</div>
                                                <div style={{ fontWeight: "700" }}>0.0</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap p-3 mt-3 justify-content-between profile-container gap-3' style={{ backgroundColor: "white", borderRadius: "10px" }}>
                            {/* <div className='col-4'> */}
                            <div className='gradiant-orange py-2 px-3 text-center' style={{ borderRadius: "10px", minWidth: "30%" }}>
                                <div style={{ color: "white", fontWeight: "600" }}>PLAYED</div>
                                <div style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>7</div>
                            </div>
                            {/* </div> */}
                            {/* <div className='col-4'> */}
                            <div className='gradiant-green py-2 px-3 text-center' style={{ borderRadius: "10px", minWidth: "30%" }}>
                                <div style={{ color: "white", fontWeight: "600" }}>AVERAGE</div>
                                <div style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>84(+12)</div>
                            </div>
                            {/* </div>
                            <div className='col-4'> */}
                            <div className='gradiant-blue py-2  text-center' style={{ borderRadius: "10px", minWidth: "30%" }}>
                                <div style={{ color: "white", fontWeight: "600" }}>GHIN HANDICAP</div>
                                <div style={{ color: "white", fontSize: "15px", fontWeight: "600" }}>11.3</div>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className='mt-3 p-4 gradiant-gray d-flex justify-content-around' style={{ borderRadius: "10px" }}>
                    <div className='d-flex justify-content-center data-container' style={{ flexWrap: "wrap", rowGap: "1rem" }}>
                        <div className='d-flex align-items-center col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", cursor: "pointer", minHeight: "5rem" }}>
                            <div className='d-flex justify-content-between align-items-center' style={{ justifyContent: "space-between", width: "100%" }}>
                                <div className='d-flex align-items-center gap-4 flex-wrap'>
                                    <img src={Facebook} height={40} />
                                    <img src={Twitter} height={40} />
                                    <img src={Linkdin} height={40} />
                                    <img src={Instagram} height={40} />
                                    <img src={Whatsapp} height={40} />
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    <img src={Complete} height={25} />
                                    <img src={LeftArrow} height={15} />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", cursor: "pointer", minHeight: "5rem", flexDirection: "column" }}>
                            <div className='d-flex justify-content-between align-items-center' style={{ justifyContent: "space-between", width: "100%" }}>
                                <div className='d-flex align-items-center gap-2'>
                                    <img src={userData?.countryFlag} height="30px" />
                                    <div style={{ fontWeight: "bold" }}>{userData.cityName}, {userData.countryName}</div>
                                </div>
                                <div className='d-flex align-items-center gap-2'>
                                    {userData?.email && <img src={Complete} height={25} />}
                                    <img src={LeftArrow} height={15} />
                                </div>
                            </div>
                            <hr
                                className='mt-2'
                                style={{
                                    color: "black",
                                    height: 1,
                                    width: "inherit"
                                }}
                            />
                            <div style={{ width: "inherit", rowGap: "5px" }}>
                                {userData?.dob && <div className='d-flex'>
                                    <div className="col-2" style={{ fontWeight: "bold" }}>D.O.B.:</div>
                                    <div className="col-10">{moment(userData?.dob).format("DD-MMM-YYYY")}</div>
                                </div>}
                                {userData?.age > 0 && <div className='d-flex'>
                                    <div className="col-2" style={{ fontWeight: "bold" }}>Age:</div>
                                    <div className="col-10">{userData?.age}</div>
                                </div>}
                                {userData?.sex && <div className='d-flex'>
                                    <div className="col-2" style={{ fontWeight: "bold" }}>Sex:</div>
                                    <div className="col-10">{userData?.sex}</div>
                                </div>}
                                {userData?.phoneNumber && <div className='d-flex'>
                                    <div className="col-2" style={{ fontWeight: "bold" }}>Phone:</div>
                                    <div className="col-10">{`(${userData?.phoneCode}) ${userData?.phoneNumber}`}</div>
                                </div>}
                                {userData?.email && <div className='d-flex'>
                                    <div className="col-2" style={{ fontWeight: "bold" }}>Email:</div>
                                    <div className="col-10">{userData?.email}</div>
                                </div>}
                            </div>
                        </div>
                        {profileMenu.map((menu, index) => (
                            <>
                                <div className='d-flex align-items-center col-12 p-3' key={index} style={{ backgroundColor: "white", borderRadius: "10px", cursor: "pointer", minHeight: "5rem" }} onClick={() => setOpenDetails(menu.title === openDetails ? false : menu.title)}>
                                    <div className='d-flex justify-content-between align-items-center' style={{ justifyContent: "space-between", width: "100%" }}>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-3'>
                                                <img src={menu.icon} height="30px" />
                                            </div>
                                            <div>
                                                <div style={{ fontSize: "18px", fontWeight: "bold" }}>{menu.title}</div>
                                                <div>{menu.subTitle}</div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center gap-2'>
                                            {/* {
                                                menu.title === profileMenu[0].title ?
                                                    (userData?.userEmails?.length > 0 && userData?.userAddress?.length > 0 && userData?.userPhones?.length > 0 && userData?.userEmails?.find((email => email?.isVerified)) && userData?.userAddress?.find((address => address?.isVerified)) && userData?.userPhones?.find((phone => phone?.isVerified))) ? true : false :
                                                    menu.title === profileMenu[1].title ?
                                                        (userData?.photoFront && userData?.photoSide) ? true : false :
                                                        menu.title === profileMenu[2].title ?
                                                            userData?.golfPreference?.courses?.length > 0 ? true : false :
                                                            menu.title === profileMenu[3].title ?
                                                                userData?.userCards?.length > 0 ? true : false :
                                                                menu.title === profileMenu[4].title ?
                                                                    userData?.userEmergencyContacts?.length > 0 ? true : false :
                                                                    menu.title === profileMenu[5].title ?
                                                                        userData?.confirmation && Object.keys(userData?.confirmation).find((data) => { if (userData?.confirmation[data] === true) return true }) ? true : false :
                                                                        false
                                                                        && <img src={Complete} height={25} />} */}
                                            {menu.title === profileMenu[0].title && (userData?.userEmails?.length > 0 && userData?.userAddress?.length > 0 && userData?.userPhones?.length > 0 && userData?.userEmails?.find((email => email?.isVerified)) && userData?.userPhones?.find((phone => phone?.isVerified))) && <img src={Complete} height={25} />}
                                            {menu.title === profileMenu[1].title && userData?.photoFront && userData?.photoSide && <img src={Complete} height={25} />}
                                            {menu.title === profileMenu[2].title && userData?.golfPreference?.courses?.length > 0 && <img src={Complete} height={25} />}
                                            {menu.title === profileMenu[3].title && userData?.userCards?.length > 0 && <img src={Complete} height={25} />}
                                            {menu.title === profileMenu[4].title && userData?.userEmergencyContacts?.length > 0 && <img src={Complete} height={25} />}
                                            {menu.title === profileMenu[5].title && userData?.confirmation && !Object.keys(userData?.confirmation).find((data) => { if (userData?.confirmation[data] === false) return true }) && <img src={Complete} height={25} />}
                                            {menu.title === profileMenu[6].title && userData?.consent && !Object.keys(userData?.consent?.required).find((data) => { if (userData?.consent?.required[data] === false) return true }) && <img src={Complete} height={25} />}
                                            <img src={LeftArrow} height={15} style={{ rotate: openDetails === menu.title && "90deg" }} />
                                        </div>
                                    </div>
                                </div>
                                {openDetails === menu.title &&
                                    <>
                                        {openDetails === profileMenu[0].title && <ContactDetails userData={userData} setOpenDetails={setOpenDetails} />}
                                        {openDetails === profileMenu[1].title && <PhotoDetails userData={userData} setOpenDetails={setOpenDetails} />}
                                        {openDetails === profileMenu[2].title && <GolfPreferences userData={userData} setOpenDetails={setOpenDetails} />}
                                        {openDetails === profileMenu[3].title && <CreditCardDetails userData={userData} setOpenDetails={setOpenDetails} />}
                                        {openDetails === profileMenu[4].title && <EmergencyContact userData={userData} setOpenDetails={setOpenDetails} />}
                                        {openDetails === profileMenu[5].title && <ConfirmationDetails userData={userData} setOpenDetails={setOpenDetails} />}
                                        {openDetails === profileMenu[6].title && <ConsentDetails userData={userData} setOpenDetails={setOpenDetails} />}
                                    </>
                                }
                            </>
                        ))}
                    </div>
                </div>
            </div >
        </>
    )
}
