import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useForm, Controller } from 'react-hook-form';
import FullLogo from "../../../assets/utils/images/full-logo.png"
import "../Userpages.css"
import { loginUser } from "../../../service/authService";
import { toast } from "react-toastify";
import toastConfig from "../../../config/toast-config";
import { loginForm } from "../../../config/formValidation";
import PhoneNumber from "../../../components/PhoneNumber";
import { useState } from "react";
import { useEffect } from "react";
import { getCountryCode } from "../../../service/configService";
import { errorMessage } from "../../../helpers/catchErrorMessage";
import { onlyNumber } from "../../../config/validationRegex";
import { loaderContext } from "../../../config/context";
// Layout

export default function LoginBoxed() {
  const navigate = useNavigate()
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const [countryCode, setCountryCode] = useState({})
  const [countryCodes, setCountryCodes] = useState([]);
  useEffect(async () => {
    setIsLoading(true)
    try {
      const { data } = await getCountryCode();
      setCountryCodes(data?.data)
      setCountryCode(data?.data?.find((country) => country.countryCode === "+66"))

    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }, [])
  const sendOTP = async (formData) => {
    setIsLoading(true)
    try {
      formData.phoneCode = countryCode?.countryCode;
      // formData.phoneNumber = "7069736996";
      const { data } = await loginUser(formData);
      localStorage.setItem("userId", data?.data?._id)
      toast.success(data?.data?.otp, toastConfig)
      navigate("/verify-otp", { state: { phoneCode: countryCode?.countryCode, phoneNumber: formData?.phoneNumber } })
    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }
  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useForm();
  return (
    <Fragment>
      <div className="mx-auto mb-3" />
      <div className="modal-dialog w-100 mx-auto">
        <div className="modal-content">
          <Form>
            <div className="d-flex align-items-center justify-content-center w-100">
              <img src={FullLogo} height="220rem" width="400rem" />
            </div>
            <div className="modal-body pt-2">
              <h2 className="user-page-heading">LOGIN</h2>
              <div className="h5 modal-title text-center">
                <h4 className="mt-2">
                  <span> Log in with your mobile number. We'll send your login code to this number.</span>
                </h4>
              </div>
              {/* <Form> */}
              <Row form>
                <Col md={12}>
                  <FormGroup>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={{ required: true, minLength: 9, pattern: onlyNumber }}
                      render={({ field: { value, onChange } }) => (
                        // <Input type="text" className={`${errors.phoneNumber ? "is-invalid" : ""}`} maxLength="12" name="phoneNumber" id="loginPhone" size="lg" placeholder="Phone Number" value={value} onChange={onChange} />
                        <PhoneNumber
                          error={errors.phoneNumber ? "is-invalid" : ""}
                          onChange={onChange}
                          value={value}
                          placeholder="Phone Number"
                          countryCode={countryCode}
                          setCountryCode={setCountryCode}
                          allCountryCodes={countryCodes}
                          dropdownDirection="bottom"
                          maxLength={12}
                        />
                      )}
                    />
                    {errors.phoneNumber && (
                      <small className="text-danger">
                        {errors.phoneNumber.type === 'required' && (
                          <span>
                            {loginForm.phoneNoReq}
                          </span>
                        )}
                        {errors.phoneNumber.type === 'minLength' && (
                          <span>
                            {loginForm.phoneNoInvalid}
                          </span>
                        )}
                        {errors.phoneNumber.type === 'pattern' && (
                          <span>
                            {loginForm.phoneNoInvalid}
                          </span>
                        )}
                      </small>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {/* </Form> */}
              {/* <div className="d-flex justify-content-end">
              <div className="btn text-primary">
                <a onClick={() => navigate("/forgot-password")}>
                  Forgot Password
                </a>
              </div>
            </div> */}
            </div>
            <div className="modal-footer clearfix">
              <div className="w-100">
                <Button color="primary" type="submit" className="d-flex w-100 justify-content-center" style={{ color: "#ffffff" }} onClick={handleSubmit(sendOTP)} size="lg">
                  <div className="fs-5">SEND</div>
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  )
}
