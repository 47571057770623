import React, { useContext, useEffect } from 'react'
import ReactTables from '../../components/ReactTable'
import PageTitleAlt from '../../Layout/AppMain/PageTitleAlt';
import UsersIcon from "../../assets/icons/users.svg"
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg"
import { ReactComponent as View } from "../../assets/icons/view.svg"
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, UncontrolledButtonDropdown } from 'reactstrap';
import { loaderContext } from '../../config/context.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { errorMessage } from '../../helpers/catchErrorMessage.js';
import { getCoursesData } from '../../service/golfService.js';

export default function GolfCourse() {
    const [holesData, setholesData] = useState([]);
    const [showColumns, setShowColumns] = useState([]);
    const [colors, setColors] = useState([{ lable: "BLUE", value: "Blue", bg: "linear-gradient(45deg, #117CB4 0%, #084768 100%)", border: "linear-gradient(45deg, #117CB4 0%, #084768 100%)" }, { lable: "WHITE", value: "White", bg: "radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%)", border: "#000000" }, { lable: "YELLOW", value: "Yellow", bg: "linear-gradient(45deg, #DEA22A 0%, #815627 100%)", border: "linear-gradient(45deg, #DEA22A 0%, #815627 100%)" }, { lable: "RED", value: "red", bg: "#B40000", border: '#B40000' }])
    const [selectedColor, setSelectedColor] = useState({ lable: "BLUE", value: "Blue", bg: "linear-gradient(45deg, #117CB4 0%, #084768 100%)", border: "linear-gradient(45deg, #117CB4 0%, #084768 100%)" })
    const [showFilter, setShowFilter] = useState(false)
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const navigate = useNavigate()

    const getCourses = async () => {
        setIsLoading(true)
        try {
            const { data } = await getCoursesData()
            setholesData(data?.data?.course?.holeDetails)
            console.log("Golf Dsta", data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getCourses()
        setShowColumns(columns)
    }, [])
    const columns = [
        {
            id: 1,
            name: "#",
            selector: row => row?.name.split(" ")[1],
            sortable: true,
            value: true,
        },
        {
            id: 2,
            name: "PAR",
            selector: row => row?.noOfPar,
            sortable: true,
            value: true,
        },
        {
            id: 3,
            name: "BLUE TEE",
            selector: row => `${row.distance[0].distance} ${row.distance[0].distanceType.toUpperCase()}`,
            sortable: true,
            value: true,
        },
        {
            id: 4,
            name: "WHITE TEE",
            selector: row => `${row.distance[1].distance} ${row.distance[0].distanceType.toUpperCase()}`,
            sortable: true,
            value: true,
            width: "7rem",
        },
        {
            id: 5,
            name: "GOLD TEE",
            selector: row => `${row.distance[2].distance} ${row.distance[0].distanceType.toUpperCase()}`,
            sortable: true,
            value: true,
        },
        {
            id: 6,
            name: "RED TEE",
            selector: row => `${row.distance[3].distance} ${row.distance[0].distanceType.toUpperCase()}`,
            sortable: true,
            value: true,
        },
        {
            id: 7,
            name: "END FAIRWAY",
            selector: row => row.ef,
            sortable: true,
            value: true,
            width: "8rem",
        },
        {
            id: 8,
            name: "CENTER",
            selector: row => row.center,
            sortable: true,
            value: true,
        },
        {
            id: 9,
            name: "PHOTOS",
            selector: row => row.images.length,
            sortable: true,
            value: true,
        },
        {
            id: 10,
            name: "VIDEOS",
            selector: row => row.videos.length,
            sortable: true,
            value: true,
        },
        {
            id: 11,
            name: "RATINGS",
            selector: row => row.ratings,
            sortable: true,
            value: true,
        },
        {
            id: 12,
            name: "ACTION",
            value: true,
            selector: data => (
                <div className='d-flex'>
                    <span
                        className="btn me-2 action-button"
                        title="View"
                        onClick={() => onViewUser(data._id)}
                    >
                        {/* <img src={View} height={18} width={18} style={{ fill: "#ffffff", stroke: "#ddffdd" }} /> */}
                        <View height={18} width={18} className='svg-icon' />
                    </span>
                </div>
            ),
        }
    ];
    const onViewUser = (data) => {

    }
    const setColumnValue = (column) => {
        const columns = showColumns.map((columnName) => {
            if (columnName.id === column) {
                columnName.value = !columnName.value
            }
            return columnName;
        })
        setShowColumns(columns)
    }

    return (
        <>
            <PageTitleAlt heading="GOLF COURSE"
                subheading="PAR - DISTANCEs - MAPS - MEDIA"
                icon={<img src={UsersIcon} style={{ height: "100%", width: "100%", fill: "white" }} />}
                button={
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" className="ms-2">
                            <span className="opacity-7 me-1">
                                <FontAwesomeIcon icon={faCog} color="#ffffff" />
                            </span>
                            <span color="#000000" style={{ color: "#ffffff" }}>Actions</span>
                        </DropdownToggle>
                        <DropdownMenu rigth={false} flip={true}>
                            <div className="px-3 py-1">

                            </div>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                }
            />
            <div className="row mb-3 g-3">
                <ReactTables
                    columns={showColumns.filter((col) => col.value)}
                    data={holesData}
                    tableHeading="18 HOLES"
                    searchColumns={[{ key: "name" }, { key: "noOfPar" }]}
                    filterComponent={
                        <div style={{ display: "block", maxWidth: "fit-content" }}>
                            <Button className="d-flex justify-content-center btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => setShowFilter(!showFilter)}>
                                <FilterIcon height={20} width={20} className="filter-btn" />
                            </Button>
                            {showFilter && <div className='position-absolute card py-2 px-3' style={{ marginTop: "1%", marginLeft: "-10%", width: "10rem", zIndex: "9999" }}>
                                {showColumns.map((column, index) => (
                                    <div className="py-1" key={index}>
                                        <FormGroup check>
                                            <Input type="checkbox" checked={column.value} onClick={() => setColumnValue(column.id)} />
                                            <Label check>
                                                {column.name}
                                            </Label>
                                        </FormGroup>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    }
                    headerLeft={
                        <UncontrolledButtonDropdown className="me-2">
                            <Button outline color="secondary" className='d-flex align-items-center'>
                                <div className='p-2 me-2' style={{ background: selectedColor?.bg, borderRadius: "100%" }}></div>{selectedColor?.lable}
                            </Button>
                            <DropdownToggle outline className="dropdown-toggle-split" caret color="secondary" />
                            <DropdownMenu>
                                {colors.map((col) => (
                                    <DropdownItem onClick={() => setSelectedColor(col)}><div className='p-2 me-2' style={{ background: col?.bg, borderRadius: "100%" }}></div>{col?.lable}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                    SecondHeader={
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex bg-page-title-icon justify-content-between px-3 py-2' style={{ width: "15%", borderRadius: "3px" }}>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>PAR</div>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>72</div>
                            </div>
                            <div className='d-flex bg-page-title-icon justify-content-between px-3 py-2' style={{ width: "15%", borderRadius: "3px" }}>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>YARDS</div>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>6806</div>
                            </div>
                            <div className='d-flex bg-page-title-icon justify-content-between px-3 py-2' style={{ width: "15%", borderRadius: "3px" }}>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>RATINGS</div>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>72.1</div>
                            </div>
                            <div className='d-flex bg-page-title-icon justify-content-between px-3 py-2' style={{ width: "15%", borderRadius: "3px" }}>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>SLOPE</div>
                                <div className='text-white' style={{ fontSize: "20px", fontWeight: "600" }}>135</div>
                            </div>
                        </div>
                    }
                />
                {/* </div>
            </div> */}
            </div>
        </>
    )
}
