import style from "../../src/assets/themes/default/_variables.scss"
export default {
  confirmation: {
    showCancelButton: true,
    confirmButtonColor: style.primary,
    cancelButtonColor: style.danger
  },
  simple: {
    confirmButtonColor: style.primary
  }
};
