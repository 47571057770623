import React from 'react'

export default function PhotoDetails({ userData }) {
    return (
        <div className='col-12 p-3' style={{ backgroundColor: "white", borderRadius: "10px", minHeight: "5rem" }}>
            <div className='bg-primary col-12 px-3 py-2' style={{ borderRadius: "10px", fontSize: "16px", fontWeight: "bold", color: "white" }}>
                PHOTO DETAILS
            </div>
            {(userData?.photoFront && userData?.photoSide) ? <div className='d-flex flex-wrap align-items-center justify-content-between mt-3'>
                <div className='text-center'>
                    <img src={userData?.photoFront} style={{ width: "13.5rem", height: "13.5rem", borderRadius: "10px" }} />
                    <div className='mt-2' style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>Front Photo</div>
                </div>
                <div className='text-center'>
                    <img src={userData?.photoSide} style={{ width: "13.5rem", height: "13.5rem", borderRadius: "10px" }} />
                    <div className='mt-2' style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>Side Photo</div>
                </div>
            </div>
                :
                <div className='text-center text-danger mt-3' style={{ fontSize: "16px", fontWeight: "600" }}>
                    No Data Found
                </div>
            }
        </div>
    )
}
