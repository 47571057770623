import React, { useContext } from 'react'
import ReactTables from '../../components/ReactTable'
import PageTitleAlt from '../../Layout/AppMain/PageTitleAlt';
import { ReactComponent as Delete } from "../../assets/icons/delete.svg"
import { ReactComponent as Edit } from "../../assets/icons/edit.svg"
import { ReactComponent as Suspend } from "../../assets/icons/suspend.svg"
import UsersIcon from "../../assets/icons/users.svg"
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg"
import { ReactComponent as AddIcon } from "../../assets/icons/Add.svg"
import sweetalertConfig from '../../config/sweetalert-config.js';
import { useState } from 'react';
import { useEffect } from 'react';
import { errorMessage } from '../../helpers/catchErrorMessage';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Button, DropdownMenu, DropdownToggle, FormGroup, Input, Label, UncontrolledButtonDropdown } from 'reactstrap';
import { activateMasterAdmin, deleteMasterAdmin, getAllMasterAdmins, suspendMasterAdmin } from '../../service/masterAdminService';
import { isConstructorDeclaration } from 'typescript';
import { loaderContext } from '../../config/context.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export default function AdminMaster() {
    const [usersData, setUsersData] = useState([]);
    const [showColumns, setShowColumns] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const navigate = useNavigate()
    const loadUserData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getAllMasterAdmins();
            setUsersData(data?.data?.map((user) => {
                user.firstName = `${user.firstName} ${user.lastName}`;
                user.phoneNumber = `${user.phoneCode}-${user.phoneNumber}`
                return user
            }));
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        loadUserData()
        setShowColumns(columns)
    }, [])
    const columns = [
        {
            id: 1,
            name: "NAME",
            selector: row => row.firstName,
            sortable: true,
            value: true,
        },
        {
            id: 2,
            name: "EMAIL",
            selector: row => row.email,
            sortable: true,
            value: true,
        },
        {
            id: 3,
            name: "PHONE NUMBER",
            selector: row => row.phoneNumber,
            sortable: true,
            value: true,
        },
        {
            id: 4,
            name: "STATUS",
            selector: row => row.status,
            sortable: true,
            value: true,
        },
        {
            id: 5,
            name: "ACTION",
            value: true,
            selector: data => (
                <div className='d-flex'>
                    <span
                        className="btn me-2 action-button"
                        title="Edit"
                        onClick={() => onEditUser(data._id)}
                    >
                        {/* <img src={Edit} height={15} width={15} fill="#fffff" /> */}
                        <Edit height={15} width={15} className='svg-icon' />
                    </span>
                    <span
                        className="btn me-2 action-button"
                        title="Suspend"
                        onClick={() => onSuspendUser(data)}
                    >
                        {/* <img src={Suspend} height={15} width={15} /> */}
                        <Suspend height={15} width={15} className='svg-icon' />
                    </span>
                    <span
                        className="btn action-button"
                        title="Delete"
                        onClick={() => onDeleteUser(data._id)}
                        style={{ fontWeight: "bold" }}
                    >
                        {/* <img src={Delete} height={15} width={15} style={{ fontWeight: "bold" }} /> */}
                        <Delete height={15} width={15} className='svg-icon' />
                    </span>
                </div>
            ),
        }
    ];
    const setColumnValue = (column) => {
        const columns = showColumns.map((columnName) => {
            if (columnName.id === column) {
                columnName.value = !columnName.value
            }
            return columnName;
        })
        setShowColumns(columns)
    }
    const onEditUser = (data) => {
        console.log("Dsta", data)
        navigate(`/edit-admin`, { state: { userId: data } })
    }
    const onAddUser = (data) => {
        navigate(`/add-admin`, { state: { userId: data } })
    }
    const onDeleteUser = (userData) => {
        console.log("uerId", userData)
        Swal.fire({
            title: "Delete",
            text: "Are you sure you want to delete this admin?",
            icon: 'warning',
            ...sweetalertConfig.confirmation,
            confirmButtonText: "Delete"
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const { data } = await deleteMasterAdmin(userData);
                    // setUsersData(usersData.filter((user) => user._id.toString() != userData.toString))
                    loadUserData()
                    Swal.fire({
                        icon: 'success',
                        title: "Deleted",
                        text: data.message,
                        ...sweetalertConfig.simple
                    });
                } catch (e) {
                    errorMessage(e)
                }
            }
        });
    }
    const onSuspendUser = (userData) => {
        if (userData?.status === "suspend") {
            Swal.fire({
                title: "Activate",
                text: "Are you sure you want to activate this admin?",
                icon: 'warning',
                ...sweetalertConfig.confirmation,
                confirmButtonText: "Activate"
            }).then(async result => {
                if (result.isConfirmed) {
                    try {
                        const { data } = await activateMasterAdmin(userData._id);
                        Swal.fire({
                            icon: 'success',
                            title: "Activated",
                            text: data.message,
                            ...sweetalertConfig.simple
                        });
                        loadUserData();
                    } catch (e) {
                        errorMessage(e)
                    }
                }
            });
        } else {
            Swal.fire({
                title: "Suspend",
                text: "Are you sure you want to suspend this admin?",
                icon: 'warning',
                ...sweetalertConfig.confirmation,
                confirmButtonText: "Suspend"
            }).then(async result => {
                if (result.isConfirmed) {
                    try {
                        const { data } = await suspendMasterAdmin(userData._id);
                        Swal.fire({
                            icon: 'success',
                            title: "Suspended",
                            text: data.message,
                            ...sweetalertConfig.simple
                        });
                        loadUserData();
                    } catch (e) {
                        errorMessage(e)
                    }
                }
            });
        }
    }
    return (
        <>
            <PageTitleAlt heading="ADMINS"
                subheading="This is the list of all admins."
                icon={<img src={UsersIcon} style={{ height: "100%", width: "100%", fill: "white" }} />}
                button={
                    <Button className="d-flex justify-content-center btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => navigate("/add-admin")}>
                        <AddIcon height={20} width={20} className="filter-btn" />
                    </Button>
                }
            />
            <div className="row mb-3 g-3">
                {/* <div className="card overflow-hidden mb-3"> */}
                {/* <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    Users
                                </h3>
                            </div>
                        </div>
                    </div> */}

                {/* <div className="card-body pt-3" style={{ minHeight: "25rem" }}> */}
                <ReactTables
                    columns={showColumns.filter((col) => col.value)}
                    data={usersData}
                    tableHeading="ADMINS"
                    searchColumns={[{ key: "firstName" }, { key: "email" }, { key: "phoneNumber" }, { key: "status" }]}
                    // pageSizeOption={[100, 150, 200, 250]}
                    // minWidth="10rem"
                    filterComponent={
                        <div style={{ display: "block", maxWidth: "fit-content" }}>
                            {/* <Button className={`d-flex align-items-center p-0 justify-content-center table-filter-btn ${showFilter ? "bg-primary" : ""}`} style={{ width: "2.5rem", height: "2rem" }} onClick={() => setShowFilter(!showFilter)}>
                                <img src={FilterIcon} height={20} width={20} style={{ fill: "#ffffff" }} />
                            </Button> */}
                            <Button className="d-flex justify-content-center btn-icon btn-icon-only btn-shadow btn-outline-2x ms-3" outline color="secondary" onClick={() => setShowFilter(!showFilter)}>
                                {/* <img src={FilterIcon} height={20} width={20} style={{ fill: "#00000" }} /> */}
                                <FilterIcon height={20} width={20} className="filter-btn" />
                            </Button>
                            {showFilter && <div className='position-absolute card py-2 px-3' style={{ marginTop: "1%", marginLeft: "-10%", width: "10rem", zIndex: "9999" }}>
                                {showColumns.map((column, index) => (
                                    <div className="py-1" key={index}>
                                        <FormGroup check>
                                            <Input type="checkbox" checked={column.value} onClick={() => setColumnValue(column.id)} />
                                            <Label check>
                                                {column.name}
                                            </Label>
                                        </FormGroup>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    }
                />
                {/* </div>
                </div> */}
            </div>
        </>
    )
}
