import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export default function Loader() {
    return (
        <div style={{ display: "flex", position: "absolute", height: "100%", width: "100%", opacity: "0.9", alignItems: "center", justifyContent: "center", zIndex: "9999", backgroundColor: "black" }}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                visible={true}
            />
        </div>
    )
}
